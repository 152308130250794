import React from 'react'
import { useState } from 'react';
import style from '../../css/stacks.module.css'
import { Helmet } from 'react-helmet';
export const Stack = () => {

    // State to manage the accordion panel visibility
  const [isAccordionOpen, setAccordionOpen] = useState(false);

  // Function to toggle the accordion
  const toggleAccordion = () => {
    setAccordionOpen(!isAccordionOpen);
  };
  return (


    <>
        <Helmet>
            <link rel="canonical" href="https://computerneek.org/stack" />

        </Helmet> 
        <div className={style.banner}>
            
            <h2>Stacks</h2>
        </div>

        <section className={style.stackSection}>
            <div className={style.stackWriting}>
                <h3 id={style.stackHighlights}>What is a stack</h3>
                <p>A stack is just a collection of data in a linear format that is added from the bottom to the top but items are removed from top to bottom. This is called LIFO - Last in first out.</p>
                <h3 id={style.stackHighlights}>There are five operations of a stack </h3>
                <div className="operations">
                    <p>Push(data)<br /> pop()<br />peak()<br />is_empty()<br />is_full()</p>
                </div>
                <h3 id={style.stackHighlights}>Push(data)</h3>

                <p>In a stack, there is a top. This top represents the item that has recently been added to the stack. An array is usually used to hold the items in it but a list can be used too. So in programming terms, if you add "item 1" and "item 2" to the stack, the current top position would be at index position 1 because you've added two items (remember that indexes start at 0 and not 1).<br /><br />"item1" is at index 0 and "item 2" is at index position 1. If you were to use an array in this example and wanted to add another item to the stack then you would first increment the top so it goes to the next available space in the array and then add the item to that array index. Look at the example below that illustrates this.</p>
                <div className="example">
                    <h4 className="h4Example">Example</h4>
                    <div className={style.stackCode}>
                        <pre className="csharp" style={{fontFamily:"monospace"}}></pre>
                        <span style={{color: "#008080", fontStyle: "italic"}}>//before adding an item to the array</span>
                        &nbsp;<br />
                        top <span style={{color: '#008000'}}>=</span> <span style={{color: '#FF0000'}}>1</span><span style={{color: '#008000'}}>;</span>
                        &nbsp;<br /><br />
                        <span style={{color: "#008080", fontStyle: "italic"}}>//after wanting to add an item to the array</span>
                        &nbsp;<br />
                        <span style={{color: '#6666cc', fontWeight: 'bold'}}>string</span> addedItem <span style={{color: '#008000'}}>=</span> <span style={{color: "#666666"}}>&quot;item 3&quot;</span><span style={{color: '#008000'}}>;</span>
                        &nbsp;<br /><br />
                        top <span style={{color: '#008000'}}>=</span> top <span style={{color: '#008000'}}>+</span> <span  style={{color: '#FF0000'}}>1</span><span style={{color: '#008000'}}>;</span>
                        &nbsp;<br /><br />
                        arrayItems<span style={{color: '#008000'}}>&#91;</span>top<span style={{color: '#008000'}}>&#93;</span> <span style={{color: '#008000'}}>=</span> addedItem<span style={{color: '#008000'}}>;</span>
                    </div>
                </div>

                

                <p>In this example, the top variable before was 1. We then set the string variable "addedItem" to "item 3". After that we increment the top by one. This is so it goes to the next empty space in the array. After that, we set the string variable "addedItem" to the index position if the array "arrayItems". The top position is now 2.</p>

                <h3 id={style.stackHighlights}>Pop()</h3>
                <p>The pop() operation removes the top element from the stack. It does this by setting the array with the top index as null or ("") and decreasing the top count by one.</p>
                <div className="example">
                    <h4 className="h4Example">Example</h4>
                    <div className={style.stackCode}>
                        <pre className="csharp" style={{fontFamily:"monospace"}}></pre>
                        <span style={{color: "#008080", fontStyle: "italic"}}>//before removing an item </span>
                        <br />top <span style={{color: '#008000'}}>=</span> <span  style={{color: '#FF0000'}}>2</span><span style={{color: '#008000'}}>;</span>
                        <span style={{color: "#008080", fontStyle: "italic"}}><br /><br />//after removing<br /> </span>
                        arrayItems<span style={{color: '#008000'}}>&#91;</span><span  style={{color: '#FF0000'}}>2</span><span style={{color: '#008000'}}>&#93;</span> <span style={{color: '#008000'}}>=</span> <span style={{color: "#666666"}}>&quot;&quot;</span>;<br />
                        <br />top <span style={{color: '#008000'}}>=</span> top <span style={{color: '#008000'}}>-</span> <span  style={{color: '#FF0000'}}>1</span><span style={{color: '#008000'}}>;</span>
                        <span style={{color: "#008080", fontStyle: "italic"}}>// top now equals 1</span>
                    </div>
                </div>

                <h3 id={style.stackHighlights}>Peak()</h3>
                <p>Peak works by outputting the top item of the stack without increasing or decreasing the top count.</p>

                <div className="example">
                    <h4 className="h4Example">Example</h4>
                    <div className={style.stackCode}>
                        <pre className="csharp" style={{fontFamily:"monospace"}}></pre>
                        <span style={{color: "#008080", fontStyle: "italic"}}>//outputting the top item on the stack</span>
                        <br />Console<span style={{color: '#008000'}}>.</span><span style={{color: "#0000FF"}}>WriteLine</span><span style={{color: '#008000'}}>&#40;</span>arrayItems<span style={{color: '#008000'}}>&#91;</span>top<span style={{color: '#008000'}}>&#93;</span><span style={{color: '#008000'}}>&#41;</span><span style={{color: '#008000'}}>;</span>
                    </div>
                </div>

                <h3 id={style.stackHighlights}>is_full()</h3>
                <div>
                    <p>is_full() just checks if the stack is full. In a program, a static stack will have a maximum amount of data to be able to be stored. The program can check if the top equals the data size - 1 (the maximum amount that can be stored). It will return is full or if it's not full, it won't return is full.</p>
                    <h4 className="h4Example">Why would it be full if it equals the data size - 1?</h4>
                    <p>First, you have to know why we set the top at the very beginning to -1. Well, it's because the top is set at -1 to start with. This is because the top increments first before setting the data to the stack. This is because arrays and lists start at index 0. <br />The issue with 0 being a starting point is that if we are incrementing the top first, which would make the top = 1, and then set it to that index position, there will be space missed. The space missed would be index position 0. So if we set the index position to -1 first and then increment by 1, the next item to be added to the stack will be at index position 0 which is the start of an array/list.</p>
                    <br></br><p>Because we start at -1 and the first data set in the stack will be at index position 0, this means that the stack size is actually one less than the dataSize (look at the variable "dataSize" below). We humans start counting at 1 but when working with lists/arrays, the count starts at 0. This means that the lists/array count will always be one less than ours. This is why we minus 1 when we check if the stack is full.</p>
                    <div className="example">
                        <div className={style.stackCode}>
                            <pre className="csharp" style={{fontFamily:"monospace"}}></pre>
                            <span style={{color: '#6666cc', fontWeight: 'bold'}}>int</span> dataSize <span style={{color: '#008000'}}>=</span> <span  style={{color: '#FF0000'}}>5</span><span style={{color: '#008000'}}>;</span>
                            <span style={{color: '#0600FF', fontWeight: 'bold'}}><br /><br />if</span><span style={{color: '#008000'}}>&#40;</span>top <span style={{color: '#008000'}}>==</span> dataSize <span style={{color: '#008000'}}>-</span> <span  style={{color: '#FF0000'}}>1</span><span style={{color: '#008000'}}>&#41;</span>
                            <span style={{color: '#008000'}}><br />&#123;</span>
                            <br />&emsp;&emsp;&emsp;&emsp;Console<span style={{color: '#008000'}}>.</span><span style={{color: "#0000FF"}}>WriteLine</span><span style={{color: '#008000'}}>&#40;</span><span style={{color: "#666666"}}>&quot;Full&quot;</span><span style={{color: '#008000'}}>&#41;</span><span style={{color: '#008000'}}>;<br /></span>
                            <span style={{color: '#008000'}}>&#125;</span>
                        </div>
                    </div>
                </div>

                <h3 id={style.stackHighlights}>is_empty()</h3>
                <p>is_empty() checks if the stack is empty. It does this by checking if the array at the index position of the value of "top" is empty.</p>
               
          <div className={style.stackAccordioncontainer}>
            <button className={style.stackAccordion} onClick={toggleAccordion}>
              <h2>Click for Algorithm Of Stack Implementation</h2>
            </button>
            {isAccordionOpen && (
              <div className={style.panel}>
                <div id={style.createWord1}>
                    <h4>A stack algorithm C#</h4>
                    <pre className="csharp" style={{ fontFamily: 'monospace' }}>
                    <span style={{ color: '#6666cc', fontWeight: 'bold' }}>class</span> Stack
                    <span style={{ color: '#008000' }}> &#123;</span>
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color: '#0600FF', fontWeight: 'bold' }}>public</span> <span style={{ color: '#6666cc', fontWeight: 'bold' }}>string</span><span style={{ color: '#008000' }}>[]</span> items<span style={{ color: '#008000' }}>;</span>
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color: '#0600FF', fontWeight: 'bold' }}>private</span> <span style={{ color: '#6666cc', fontWeight: 'bold' }}>int</span> size<span style={{ color: '#008000' }}>;</span>
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color: '#0600FF', fontWeight: 'bold' }}>private</span> <span style={{ color: '#6666cc', fontWeight: 'bold' }}>int</span> top <span style={{ color: '#008000' }}>=</span> <span style={{ color: '#FF0000' }}>-1</span><span style={{ color: '#008000' }}>;</span>
                    <br />
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color: '#0600FF', fontWeight: 'bold' }}>public</span> Stack<span style={{ color: '#008000' }}>(</span><span style={{ color: '#6666cc', fontWeight: 'bold' }}>int</span> stackSize<span style={{ color: '#008000' }}>)</span>
                    <span style={{ color: '#008000' }}> &#123;</span>
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color: '#0600FF', fontWeight: 'bold' }}>this</span><span style={{ color: '#008000' }}>.</span><span style={{ color: '#0000FF' }}>size</span> <span style={{ color: '#008000' }}>=</span> stackSize<span style={{ color: '#008000' }}>;</span>
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color: '#0600FF', fontWeight: 'bold' }}>this</span><span style={{ color: '#008000' }}>.</span><span style={{ color: '#0000FF' }}>items</span> <span style={{ color: '#008000' }}>=</span> <span style={{ color: '#008000' }}>new</span> <span style={{ color: '#6666cc', fontWeight: 'bold' }}>string</span><span style={{ color: '#008000' }}>[</span><span style={{ color: '#0600FF', fontWeight: 'bold' }}>this</span><span style={{ color: '#008000' }}>.</span><span style={{ color: '#0000FF' }}>size</span><span style={{ color: '#008000' }}>]</span><span style={{ color: '#008000' }}>;</span>
                    <br />
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color: '#008000' }}>&#125;</span>
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color: '#008080', fontStyle: 'italic' }}>//adding to stack</span>
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color: '#0600FF', fontWeight: 'bold' }}>public</span> <span style={{ color: '#6666cc', fontWeight: 'bold' }}>void</span> push<span style={{ color: '#008000' }}>(</span><span style={{ color: '#6666cc', fontWeight: 'bold' }}>string</span> itemsAdd<span style={{ color: '#008000' }}>)</span>
                    <span style={{ color: '#008000' }}> &#123;</span>
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color: '#0600FF', fontWeight: 'bold' }}>this</span><span style={{ color: '#008000' }}>.</span><span style={{ color: '#0000FF' }}>top</span><span style={{ color: '#008000' }}>++;</span>
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color: '#0600FF', fontWeight: 'bold' }}>this</span><span style={{ color: '#008000' }}>.</span><span style={{ color: '#0000FF' }}>items</span><span style={{ color: '#008000' }}>[</span><span style={{ color: '#0600FF', fontWeight: 'bold' }}>this</span><span style={{ color: '#008000' }}>.</span><span style={{ color: '#0000FF' }}>top</span><span style={{ color: '#008000' }}>]</span> <span style={{ color: '#008000' }}>=</span> itemsAdd<span style={{ color: '#008000' }}>;</span>
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color: '#008000' }}>&#125;</span>
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color: '#008080', fontStyle: 'italic' }}>//removing from stack</span>
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color: '#0600FF', fontWeight: 'bold' }}>public</span> <span style={{ color: '#6666cc', fontWeight: 'bold' }}>void</span> pop<span style={{ color: '#008000' }}>(</span><span style={{ color: '#008000' }}>)</span>
                    <span style={{ color: '#008000' }}> &#123;</span>
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color: '#6666cc', fontWeight: 'bold' }}>string</span> removing <span style={{ color: '#008000' }}>=</span> <span style={{ color: '#0600FF', fontWeight: 'bold' }}>this</span><span style={{ color: '#008000' }}>.</span><span style={{ color: '#0000FF' }}>items</span><span style={{ color: '#008000' }}>[</span><span style={{ color: '#0600FF', fontWeight: 'bold' }}>this</span><span style={{ color: '#008000' }}>.</span><span style={{ color: '#0000FF' }}>top</span><span style={{ color: '#008000' }}>]</span><span style={{ color: '#008000' }}>;</span>
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;top<span style={{ color: '#008000' }}>--;</span>
                    <br />
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color: '#008000' }}>&#125;</span>
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color: '#008080', fontStyle: 'italic' }}>//viewing stack</span>
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color: '#0600FF', fontWeight: 'bold' }}>public</span> <span style={{ color: '#6666cc', fontWeight: 'bold' }}>void</span> peak<span style={{ color: '#008000' }}>(</span><span style={{ color: '#008000' }}>)</span>
                    <span style={{ color: '#008000' }}> &#123;</span>
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color: '#6666cc', fontWeight: 'bold' }}>string</span> peak <span style={{ color: '#008000' }}>=</span> <span style={{ color: '#0600FF', fontWeight: 'bold' }}>this</span><span style={{ color: '#008000' }}>.</span><span style={{ color: '#0000FF' }}>items</span><span style={{ color: '#008000' }}>[</span><span style={{ color: '#0600FF', fontWeight: 'bold' }}>this</span><span style={{ color: '#008000' }}>.</span><span style={{ color: '#0000FF' }}>top</span><span style={{ color: '#008000' }}>]</span><span style={{ color: '#008000' }}>;</span>
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Console<span style={{ color: '#008000' }}>.</span><span style={{ color: '#0000FF' }}>WriteLine</span><span style={{ color: '#008000' }}>(</span>peak<span style={{ color: '#008000' }}>)</span><span style={{ color: '#008000' }}>;</span>
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color: '#008000' }}>&#125;</span>
                    <br />
                    <span style={{ color: '#008000' }}>&#125;</span>
                    <br />
                    <span style={{ color: '#0600FF', fontWeight: 'bold' }}>static</span> <span style={{ color: '#6666cc', fontWeight: 'bold' }}>void</span> Main<span style={{ color: '#008000' }}>(</span><span style={{ color: '#6666cc', fontWeight: 'bold' }}>string</span><span style={{ color: '#008000' }}>[]</span> args<span style={{ color: '#008000' }}>)</span>
                    <span style={{ color: '#008000' }}> &#123;</span>
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color: '#008080', fontStyle: 'italic' }}>//setting stack size</span>
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;Console<span style={{ color: '#008000' }}>.</span><span style={{ color: '#0000FF' }}>WriteLine</span><span style={{ color: '#008000' }}>(</span><span style={{ color: '#666666' }}>&quot;What is the size of your stack&quot;</span><span style={{ color: '#008000' }}>)</span><span style={{ color: '#008000' }}>;</span>
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color: '#6666cc', fontWeight: 'bold' }}>int</span> stackSize <span style={{ color: '#008000' }}>=</span> <span style={{ color: '#6666cc', fontWeight: 'bold' }}>int</span><span style={{ color: '#008000' }}>.</span><span style={{ color: '#0000FF' }}>Parse</span><span style={{ color: '#008000' }}>(</span>Console<span style={{ color: '#008000' }}>.</span><span style={{ color: '#0000FF' }}>ReadLine</span><span style={{ color: '#008000' }}>(</span><span style={{ color: '#008000' }}>)</span><span style={{ color: '#008000' }}>)</span><span style={{ color: '#008000' }}>;</span>
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;Stack stack <span style={{ color: '#008000' }}>=</span> <span style={{ color: '#008000' }}>new</span> Stack<span style={{ color: '#008000' }}>(</span>stackSize<span style={{ color: '#008000' }}>)</span><span style={{ color: '#008000' }}>;</span>
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color: '#008080', fontStyle: 'italic' }}>//asking user for which operation</span>
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;Console<span style={{ color: '#008000' }}>.</span><span style={{ color: '#0000FF' }}>WriteLine</span><span style={{ color: '#008000' }}>(</span><span style={{ color: '#666666' }}>&quot;Do you want to peak pop or push&quot;</span><span style={{ color: '#008000' }}>)</span><span style={{ color: '#008000' }}>;</span>
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color: '#6666cc', fontWeight: 'bold' }}>string</span> options <span style={{ color: '#008000' }}>=</span> Console<span style={{ color: '#008000' }}>.</span><span style={{ color: '#0000FF' }}>ReadLine</span><span style={{ color: '#008000' }}>(</span><span style={{ color: '#008000' }}>)</span><span style={{ color: '#008000' }}>.</span><span style={{ color: '#0000FF' }}>ToLower</span><span style={{ color: '#008000' }}>(</span><span style={{ color: '#008000' }}>)</span><span style={{ color: '#008000' }}>;</span>
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color: '#0600FF', fontWeight: 'bold' }}>if</span><span style={{ color: '#008000' }}>(</span>options <span style={{ color: '#008000' }}>=</span> <span style={{ color: '#666666' }}>&quot;peak&quot;</span><span style={{ color: '#008000' }}>)</span>
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;stack<span style={{ color: '#008000' }}>.</span><span style={{ color: '#0000FF' }}>peak</span><span style={{ color: '#008000' }}>(</span><span style={{ color: '#008000' }}>)</span><span style={{ color: '#008000' }}>;</span>
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color: '#008000' }}>&#125;</span>
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color: '#0600FF', fontWeight: 'bold' }}>else</span> <span style={{ color: '#0600FF', fontWeight: 'bold' }}>if</span><span style={{ color: '#008000' }}>(</span>options <span style={{ color: '#008000' }}>=</span> <span style={{ color: '#666666' }}>&quot;pop&quot;</span><span style={{ color: '#008000' }}>)</span>
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;stack<span style={{ color: '#008000' }}>.</span><span style={{ color: '#0000FF' }}>pop</span><span style={{ color: '#008000' }}>(</span><span style={{ color: '#008000' }}>)</span><span style={{ color: '#008000' }}>;</span>
                    <br />
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color: '#008000' }}>&#125;</span>
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color: '#0600FF', fontWeight: 'bold' }}>else</span> <span style={{ color: '#0600FF', fontWeight: 'bold' }}>if</span><span style={{ color: '#008000' }}>(</span>options <span style={{ color: '#008000' }}>=</span> <span style={{ color: '#666666' }}>&quot;push&quot;</span><span style={{ color: '#008000' }}>)</span>
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Console<span style={{ color: '#008000' }}>.</span><span style={{ color: '#0000FF' }}>WriteLine</span><span style={{ color: '#008000' }}>(</span><span style={{ color: '#666666' }}>&quot;How many numbers?&quot;</span><span style={{ color: '#008000' }}>)</span><span style={{ color: '#008000' }}>;</span>
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color: '#6666cc', fontWeight: 'bold' }}>int</span> amount <span style={{ color: '#008000' }}>=</span> <span style={{ color: '#6666cc', fontWeight: 'bold' }}>int</span><span style={{ color: '#008000' }}>.</span><span style={{ color: '#0000FF' }}>Parse</span><span style={{ color: '#008000' }}>(</span>Console<span style={{ color: '#008000' }}>.</span><span style={{ color: '#0000FF' }}>ReadLine</span><span style={{ color: '#008000' }}>(</span><span style={{ color: '#008000' }}>)</span><span style={{ color: '#008000' }}>)</span><span style={{ color: '#008000' }}>;</span>
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;stack <span style={{ color: '#008000' }}>=</span> <span style={{ color: '#008000' }}>new</span> Stack<span style={{ color: '#008000' }}>(</span>amount<span style={{ color: '#008000' }}>)</span><span style={{ color: '#008000' }}>;</span>
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color: '#0600FF', fontWeight: 'bold' }}>for</span> <span style={{ color: '#008000' }}>(</span><span style={{ color: '#6666cc', fontWeight: 'bold' }}>int</span> i <span style={{ color: '#008000' }}>=</span> <span style={{ color: '#FF0000' }}>0</span><span style={{ color: '#008000' }}>; </span>i <span style={{ color: '#008000' }}>&lt;</span> amount<span style={{ color: '#008000' }}>;</span> i<span style={{ color: '#008000' }}>++</span><span style={{ color: '#008000' }}>)</span>
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color: '#008000' }}>Console</span><span style={{ color: '#008000' }}>.</span><span style={{ color: '#0000FF' }}>WriteLine</span><span style={{ color: '#008000' }}>(</span><span style={{ color: '#666666' }}>&quot;Enter in a number&quot;</span><span style={{ color: '#008000' }}>)</span><span style={{ color: '#008000' }}>;</span>
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color: '#6666cc', fontWeight: 'bold' }}>string</span> num <span style={{ color: '#008000' }}>=</span> Console<span style={{ color: '#008000' }}>.</span><span style={{ color: '#0000FF' }}>ReadLine</span><span style={{ color: '#008000' }}>(</span><span style={{ color: '#008000' }}>)</span><span style={{ color: '#008000' }}>;</span>
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;stack<span style={{ color: '#008000' }}>.</span><span style={{ color: '#0000FF' }}>push</span><span style={{ color: '#008000' }}>(</span>num<span style={{ color: '#008000' }}>)</span><span style={{ color: '#008000' }}>;</span>
                    <br />
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color: '#008000' }}>&#125;</span>
                    <br />
                    &nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color: '#008000' }}>&#125;</span>
                    </pre>
                </div>
              </div>
            )}
        </div>
            </div>

            <div className="notes">
                <h3>Overall notes</h3>
                <p>Push()-- adds items to the top of the stack</p>
                <p>Pop()-- removes items at the top of the stack</p>
                <p>Peak()-- views the item at the top of the stack</p>
                <p>is_full() -- checks if the stack is full</p>
                <p>is_empty() -- checks if stack is empty</p>
                <p>A stack is a LIFO. Last in first out</p>
            </div>
            <p id="indent">To read about Queues click<a href="queues.html"> here</a> or to read about different topics, click <a href="programmingtheory.html">here</a></p>
</section>

        

        
       
    </>
  )
}
