import React from 'react'
import { Link } from 'react-router-dom'
import footer from '../../css/footer.css'

export const Footer = () => {

  return (

    <footer>
        <div className="support">
            <p id="writing">Support</p>
            <p id="heart">&hearts;</p>

        </div>
        

        <div id="donation">
            <Link to={"https://www.paypal.com/donate/?hosted_button_id=PLVDXLJD69KKN"}><button id="donate">Donate</button></Link>
        
            <div>
                <p>I would really appreaciate it if you could help fund this page. Click donate to do so...thanks!</p>
            </div>
                
        </div>
        <div className="socials">
            <p>This page is mainly aimed at aqa a level computer science but can be used for any exam board or anyone in general</p>

            <p>If you see any mistakes on this page please Email: computernerd134@gmail.com</p>
            
        </div>
   
    </footer>

  )
}
