const questionsData = {
    "questions" : {
        "What are the 5 main functions of an OS?" : "Memory Management, Processor Scheduling, Backing store Management, Peripheral Management / I/O Management.",
        "What is processor scheduling?" : "Allocates time slices for different applications.",
        "What is ROM?" : "Contains bootstrap information that is loaded up when the computer starts.",
        "How many bits does a half adder add together?" : "2 bits.",
        "How many bits does a full adder add together?" : "3 bits.",
        "What are the type of inputs that are needed for a OR gate to return true or false" : "At least one input must be true for the output to be true else the output is false.",
        "What requirements must be reached for an Not gate to return true or false" : "The input has to a be a false for the output to be true and the for the output to be false the input must be a true.",
        "Why do companies sell software in byte code?" : "So that software can't be reversed engineered to find the source code.",
        "What is meant by imperative high level programming languages?" : "When programmers specify what instructions are and are written in our language.",
        "What is the AQA specification boolean algebra sign for the OR gate?": "A + B",
        "What is Bit rate?" : "Number of bits transmitted per second over a wired or wireless data link.",
        "What is Baud rate?": "Rate at which the signal communication channel changes state.",
        "What requirements must be reached for an NAND gate to return true or false" : "If both inputs are true then the result is false, otherwise the result is true.", 
        "What are translators?": "Translators are used to convert high level programming languages into low level programming languages.",
        "Name 3 types of translators" : "Compiler, Interpreter, Assembler",
        "What are the advantages of an interpreter(translator)?" : "Executes line by line and stops when there is an error, hence making it easier to debug. Analysing is quick.",
        "What is the disadvantage of an interpreter(translator)?" : "Execution time is longer.",
        "What are the advantages of an assembler?" : "Allows for complex jobs to be easier. Memory efficient as it requires less memory. Its fast as execution time is small. It also has a one-to-one correspondence between machine code instruction.",
    
        "What requirements must be reached for an NOR gate to return true or false?": "Both inputs have to be false for the output to be true otherwise the output is false.",
        "What are some advantages of a compiler(a translator)?" : "Compiler saves machine code so source code is no longer necessary. Compilers are Quick to execute as code is already compiled. Since code is compiled it doesn't take much memory to store.",
        "What are some disadvantages of a compiler(a translator)" : "Errors aren't usually spotted until a program is run. Also, the compiled code is hardware specific as different platforms have different binary code.",
        "What are the 4 main operations of  System Software" : "Utility software, Translators, Library Programs and thes OS.",
        "What are the 3 main operations of Application Software" : "General system, Special purpose, Bespoke Software.",
        "What is an example of a function of Utility Software?" :  "Back-up Software",
        "What is an example of a type of Translator?" : "Interpreter",
        "What is an example of a General System in Application Software?" : "Word processor",
        "What is an example of a Special System in Application Software?" : "A payroll System",
        "Why is the NAND gate know as a universal gate?" : "Different combinations of the NAND gate can act like a NOT, OR and AND gate.",
        "What are the benefits of using fewer logic gates" : "Reduces production costs, reduces heat produces, reduces energy requirement, increases the speed of a circuit.",
        "What is Utility Software?" : "Utility software is designed to analyse, configure, optimise or maintain a computer system.",
        "What is the OS?" : "A program that manages a computer's resources.",
        "What is an example of an OS(operating system)?" : "Windows",
        "What are the types of tasks a Utility software does?" : "Defragmentation, install/uninstall software, virus checker, keep software up to date.",
        "What are some advantages of Bespoke Software?" : "It's tailored for the client making it more user friendly and efficient. Client also has control over the software.",
        "What are some disadvantages of Bespoke Software" : "More expensive as companies have to cover all costs. It takes time to produce.",
        "What requirements must be reached for an XOR gate to return true or false?" : "Only one input must be true for the output to return true, otherwise the output is false.",
        "What is the AQA boolean algebra for an XOR gate?" : "A ⊕ B",
        "What does the D - Type Flip Flop do?" : "Keeps track of the value of data which is captured and committed to memory at a specific moment in time.", 
        "When does the Edge Trigger D change?" : "Only changes in the rising of the edge of the clock signal which is when it changes from 0 to 1.",
        "How many bit are in a byte?" :  "8 bits",
        "How many bits are in a nibble?" : "4 bits",
        "How many colour combinations are in 4 bits?" : "2^4 = 16. 16 different colour combinations.",
        "What is Opcode in machine code?" : "The portion of the instruction that specifies the operation that needs to be performed.",
        "What Operand in machine code?" : "Data or address used to operate the instruction.",
        "What is meant by the term Stored Program Concept?" : "When data and instruction are stored in the same memory location",
        "What type of architecture uses the Stored Program Concept?" : "The Von Neumann architecture",
        "What is the formula for working out the file size of an image with the results in bits?" : "(Colour depth x Height x Width) / 8",
        "What is the formula for working out the number of pixels in an image?" : "Height x Width",
        "What is the formula for working out the resolution of an image?" : "Height x Width",
        "What is a vector image?" : "Images created using a sequence of commands or mathematical statements.",
        "What are the advantages of vector images" : "They are scalable which ensures consistent quality if the image is enlarged. Modification on a small scale is easier. Supports layering in graphics packages.",
        "What are the disadvantages of vector images" : "A small error in a vector graphic graphic is obvious if the image is enlarged. They can't display detailed image properties like a bitmap image.",
        "What is error checking in computer science" : "When checking if bits have been corrupted by the interface during transmission and that they have been received.",
        "What is a parity bit?" : "Checks if there is an error in a sequence of bits",
        "What is the benefit of using hex to represent different colours?" : "It is shorter and easier to remember as well as easier to read.",
        "What is meta data?" : "Information about images that is stored within the image itself.",
        "What are instruction sets?" : "Commands for the computer",
        "What is direct addressing?" : "When the operand of the instruction refers directly to a location in memory.",
        "In order to process data, a sequence of operations are frequently required. As rach of these operations are executed, where are the results stored?" : "In the general purpose registers",
        "What are the advantages of Solid state drives(SSD)?" : "Solid with no moving parts. Almost twice as fast as loading up and copying files than HDD. They are also silent.",
        "How is data stored on a cd" : "Lazer beams etches pits which represents the binary number 0 and the lands(the areas that have not been etched on) represents the number 1",
        "What is a disadvantage to a 1D barcode?" : "It's only able to hold a limited amount of information",
        "What is an advantage to a 2D barcode?" : "It can encode more complex data.",
        "How does a camera read a barcode?" : "Decodes the 1D or 2D barcode",
        "How do laser scanners work?" : "Laser is reflected of a moving mirror allowing the barcode to be read in many different positions.",
        "How does a barcode scanner work?" : "The barcode emits a laser light and detects the reflected light off the barcode. The white columns reflect laser light which corresponds to a 0 and the black columns do not reflect light which corresponds to a 1.",
        "Why is parity checking in a scanner useful?" : "It helps to determine which way round the scanner has to read the barcode.",
        "How do pen style readers work? " : "The diode in the pen measures the light intensity that is reflected back. This generates a wave form. The dark coulombs absorb light and the white coulombs   reflect it. The wave form is then translated into a digital format.",
        "What are Cardinal numbers?" : "They are normal numbers we use to count.",
        "What are Ordinal numbers?" : "sequence of numbers in a numerical or alphabetical order.",
        "What are rational numbers?" : "Numbers that can be put into a fraction",
        "What are irrational numbers?" : "Numbers that can't be put in a fraction",
        "What are real numbers?" : "Numbers that are both rational and irrational",
        "What are the advantages of bitmap images?" : "Composed of blocks of pixels making it easy to edit. It's easy to compress a bitmap image into a smaller size",
        "What are the disadvantages of bitmap images?" : "File size can be very large. Not scalable as quality degrades when zoomed in.",
        "What is the principle of an ADC?" : "The Analogue signal sampled at regular intervals reference to a graph. The amplitude of the wave is measured. The measurement is coded into a fixed number of bits. ",
        "What is an advantage of open source software?" : "There is greater scrutiny on the code. Other programmers can learn from the code.",
        "Advantages of a CCD sensor in a camera?" : "Produces high quality images.",
        "What is the disadvantage of a CCD sensor in a camera?" : "Uses more power than a CMOS sensor",
        "What is an advantage of a CMOS sensor?" : "Uses much less power than a CCD sensor",
        "What is a disadvantage to a CCD sensor?" : "Uses much less power than a CCD sensor",
        "How does a RFID work?" : "A powered receiver emits a radio signal. The transponder the object then becomes energised by the radio waves. The transponder can now send data to the receiver.",
        "What is an underflow in programming?" : "An underflow is when a maths operation result smaller than what the device is capable of storing.",
        "What is an overflow in programming?" : "An overflow is when a maths operation result is larger than what the device is capable of storing.",
        "What's the difference between symmetric and asymmetric cipher systems?" : "Symmetric ciphers is when the same key is used.",
        "What makes One Time Pad ciphers so secure?" : " The key is truly random and that the key is used only once and then destroyed.",
        "Why would the actual file size for an image be larger than the minimum file size calculated?" : "Because the metadata will also be stored in the image.",
        "How is an image formed when taking a picture with a CMOS sensor?" : "A mosaic of red, green and blue filters are placed . The software then makes digital approximations in binary for each individual pixel based on the values of neighbouring pixels.",
    
        "What is clock speed?" : "The number of cycles that a processor carries per second",
    "What are the effects of increasing clock speed in a computer?": "The greater the clock speed increases the number of operations that can be processed thus the faster the computer will run.",
    "What is a disadvantage of having a higher clock speed?" : "The CPU can get too hot.",
    "What does the CPU in a computer do?" : "Fetches, decodes and executes instructions and performs logical and arithmetic operations.",
    "Which buses are bidirectional?" : "The data and the control bus.",
    "What affects how well a bus operates?" : "A thicker bus width means greater amounts of addresses, data and signals can be sent.",
    "What are the advantages of optical storage?" : "It's cheap per GB of storage and is portable.",
    "What are the disadvantages of optical storage?" : "It's  easy to break/scratch. It has slow read/write speeds. ",
    "What are the advantages of a magnetic hard drive?" : "Cheap per GB of storage. It has medium read/write speed. It also has a high capacity of storage,",
    "What are the disadvantages of a magnetic hard drive?" : "Not very portable and can be damaged if dropped. It will slow down eventually if files get fragmented.",
    "What is Moore's Law?" : "Moore's Law states that the number of transistors on a processor doubles about every 2 years.",
    "What will eventually happen if the effects of Moore's law occur?" : "A physical limit will be reached where there will be so many transistors that they produce too much heat.",
    "Why is virtual memory slower than RAM?" : "Because processing power is being taken up by moving data around rather than executing instructions.",
    "What does a heat sink do?" : "Conducts heat which allows the fan to blow it away.",
    "What is a MAC address" : "The unique identifier on a NIC. ",
    "How do switches know which devices are the devices which need to receive data?" : "The switch stores all the MAC addresses for all the devices and directs the data to the device with that mac address.",
    "What are the differences between Havard and Von Numan's CPU?" : "Havards's CPU is faster as you can receive data and instructions due to them each having their own memory. This also makes Havard's CPU more expensive. Von Numman's CPU is error prone as data and instruction can be mistaken for each other.",
    "What is the purpose of cache?" : "To store frequently used data and instructions.",
    "What is virtual memory?" : "A temporary volatile memory space created when RAM is full.",
    "What is processor scheduling?": "Allocates time slices for different applications.",
    "What does the ALU do?" : "Stores arithmetic and logic operations.",
    "What does the control unit do?" : "Regulates and integrates the operations of the computer.",
    "What is the role of the accumulator?" : "Short-term register that stores arithmetic and logic operations.",
    "What is the role of the program counter?" : "Holds the address of the next instruction to be processed.",
    "How does solid state drives save data?" : "Millions of transistors are either on or off and remain so until electricity is applied to the system. SSD's use switches called floating gate transistors. These create a special cage that traps the electron flow within it. This gate effect continues whether there is electricity in the system or not and so storage remains when power is removed.",
    "What are the advantages of a magnetic hard drive over an SSD?" : "It has more storage capacity. Magnetic hard drives are also cheaper per unit storage than SSD. Data can also be overwritten directly onto the disk with magnetic hard drive.",
    "What are 3 hardware components of a solid state drive?": "Controller, NAND gate and flash memory.",
    "What is the disadvantage of an SSD?" : "More expensive per GB.",
    
    "What happens if you increase the amount of cores in a computer?" : "It allows for more instructions to be carried out in parallel.",
    "What will happen if you increase the cache?" : "It increases the amount of data and instructions that are frequently used which decreases latency and increases performance.",
    "What are the advantages of increasing RAM in a computer?" : "It increases the speed of which RAM transfers information to other components.",
    
    "What is the full name of CIR?" : "Current instruction register.",
    "How is data able to be read from a CD-R disk?" : "Cd drive shines a laser onto the surface of the disk and detects the reflective areas and bumps by the amount of laser they reflect. The drive converts reflections into 0 and 1's.",
    "What is the difference between synchronous and asynchronous communication?" : "bits in synchronous communications are scheduled whilst bits in asynchronous communication happen on its own time and do not need scheduling.",
    "What binary digit is the start bit?" : "0",
    "What binary digit is the stop bit?" : "1",
    "Why is it more efficient to store intermediate results in a general register than in main memory?" : "It's quicker as its closer to the CPU meaning that it takes less time for data to reach it.",
    "What are the differences between primary and secondary storage?" : "Primary storage contents are not saved when the computer is off. Secondary storage contents are loaded up when the computer is switched on.",
    "What is the purpose of an interrupt?" : "To check whether a software or hardware requires attention.",
    "How does the FED cycle operate?" : "The program counter(PC) copies its contents to the MAR(Memory address register). The PC then increments by 1 and holds the address of the next instruction. The processor sends a signal to the MAR via the address bus. From there its contents are copied to the MBR via the data bus. Its contents are then copied to the CIR ready for it to be decoded. From there the instruction is decoded and executed. The CIR monitors and manages the whole process. ",
    "What are the 2 types of interrupts?" : "Hardware and software interrupts.",
    
    "What are the 3 stages to normalisation?" : "First normal form(1NF), second normal form(2NF) and third normal form(3NF)",
    "What are the advantages of normalisation?" : "It simplifies the data structure. It also reduces data redundancy and removes repeating data. It also makes it easy to update, remove and search data. It reduces the size of a database.",
    "What's the difference between flat file and relational database" : "A flat file is one big table of data whilst relational databases have many tables of data linked together.",
    "What are the disadvantages of a flat file?" : "Not all the data is needed at the same time. It also takes too much time to search and is prone to spelling mistakes.",
    "What are the advantages of a relational database?" : "There is no duplicate data, less data is stored making it smaller in size and its less prone to spelling errors.",
    "What is serialisation?" : "An technique used to ensure that transactions don't overlap in time.",
    "How does serialisation help combat the issue of multiple users accessing data at the exact same time?" : "The method only allows one transaction at a time from multiple connected clients",
    "How does timestamp ordering combat the issue of multiple users accessing data at the exact same time?" : "Every transaction will have a timestamp when it starts. The timestamp protocol orders the transactions based on the oldest protocole first.",
    "How does commitment ordering combat the issue of multiple users accessing data at the exact same time?" : "The same transactions will have priorities over others according to their dependencies as well as timestamp.",
    "How does a system know if another users has accessed the same thing using timestamp ordering?": "When a user saves an update , if the read timestamp is not the same as it was when they started the transaction, the database management system (DBMS) knows another users has accessed the same object.",
    "What are the advantages of a client-server system concerning a database": "The consistency of the database is maintained as only one copy is held. Access rights and security are managed centrally and backup and recovery are managed centrally.",
    "What are the disadvantages  of a client-server system concerning a database?" : "Simultaneous requests can cause the server to crash. The server is also a point of failure.",
    "Whats makes a database first normal form(1NF)?": "It is 1NF if it contains no repeating attributes or groups of attributes.",
    "How does record lock work in databases?" : "Locks records from modification and only allows records to be modified by one client at a time and unlocks after client is done.",
    "What are the benefits of commitment serialisation?" : "This ensures that no transactions are lost if 2 clients simultaneously try to update a record."

    }

}
export default questionsData;