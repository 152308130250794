import { Routes, Route, BrowserRouter,Navigate } from 'react-router-dom';

import Layout from './components/Layout/Layout';
import Missing  from './components/missing';
import { Home } from './components/home';
import { Backus } from './components/backus';
import { Bgo } from './components/bgo';
import { CompSystem } from './components/flashcards/compSystem';
import { Dicitonaries } from './components/dictionaries';
import { DataBaseFlash } from './components/flashcards/databaseFlash';
import { DataRep } from './components/flashcards/dataRep';
import { AllFlashcards } from './components/flashcards/allFlashcards';
import { Fsm } from './components/fsm';
import { GraphTrav } from './components/graphTrav';
import { Graphs } from './components/graphs';
import { PastB } from './components/pastB';
import { Programming } from './components/programming';
import { ProgrammingCheckpoint } from './components/programmingCheckpoint';
import { Queues } from './components/queues';
import { CompArc } from './components/flashcards/compArc';
import { Stack } from './components/stack';
import { Topics } from './components/topics';
import { ProgrammingTheory } from './components/programmingTheory';
import { TreesAndBinary } from './components/treesandbinary';
import { ReversePolishNotation } from './components/reverse-polish-notation';
import { SortingAlgorithm } from './components/sortingAlgorithm';
import { Regular } from './components/regular';
import { TreeTraversal } from './components/tree-traversal';


function App() {
 

  return (
    <BrowserRouter>
    <Routes>
      <Route path="/" element={<Navigate to="/home" />} /> {/* Redirect from root to /home */}
      <Route path="/" element={<Layout />}>
          <Route path="home" element={<Home />} />
          <Route path="programmingCheckpoint" element={<ProgrammingCheckpoint />} />
          <Route path="topics" element={<Topics />} />
          <Route path="programmingTheory" element={<ProgrammingTheory/>} />
          <Route path = "allFlashcards" element = {<AllFlashcards/>}></Route>
          <Route path="compArc" element={<CompArc/>}></Route>
          <Route path="compSystem" element={<CompSystem/>}></Route>
          <Route path="dataRep" element={<DataRep/>}></Route>
          <Route path="databaseFlash" element={<DataBaseFlash/>}></Route>
          <Route path="programming" element={<Programming/>}></Route>
          <Route path="pastB" element={<PastB/>}></Route>
          <Route path="stack" element={<Stack/>}></Route>
          <Route path="queues" element={<Queues/>}></Route>

          <Route path="graphs" element={<Graphs/>}></Route>
          <Route path="treesandbinary" element={<TreesAndBinary/>}></Route>
          <Route path="dictionaries" element={<Dicitonaries/>}></Route>
          <Route path="graph-traversals" element={<GraphTrav/>}></Route>
          <Route path="reverse-polish-notation" element={<ReversePolishNotation/>}></Route>#
          <Route path="sortingAlgorithm" element={<SortingAlgorithm/>}></Route>
          <Route path="bgo" element={<Bgo/>}></Route>
          <Route path="regular" element={<Regular/>}></Route>
          <Route path="backus" element={<Backus/>}></Route>
          <Route path="tree-traversal" element={<TreeTraversal/>}></Route>
          <Route path="sortingAlgoritm" element={<SortingAlgorithm/>}></Route>
          <Route path="bgo" element={<Bgo/>}></Route>
          <Route path="regular" element={<Regular/>}></Route>
          <Route path="backus" element={<Backus/>}></Route>

















          




          <Route path="*" element={<Missing />} />

        </Route>
    </Routes>
  </BrowserRouter>
   
    
  );
}

export default App;
