import React, { useState } from 'react';
import style from '../../css/graphs.css'; // Make sure the CSS file path is correct
import graphImg from '../../img/generalgraph.png';
import directedGraph from '../../img/directedgraph.png';
import undirectedGraph from '../../img/undirectedgraph.png';
import weightedGraph from '../../img/weightedgraph.png';
import adjancyList from '../../img/adjacencylist.png';
import adjancyMatrix from '../../img/adjacencymatrix.png';
import { Helmet } from 'react-helmet';

export const Graphs = () => {
 
  const [openPanel, setOpenPanel] = useState(false);

  const togglePanel = () => {
    setOpenPanel(!openPanel);
  };

  return (

    <>
      <Helmet>
        <meta name="keywords" content="graph, aqa computing, computer science, Data Structures, Algorithms, Programming, aqa alevel computer science spec, aqa a level computing, Programming Examples, Computerneek"/>
        <meta name="description" content="A graph is a dynamic data structure that can represent non-linear relationships betweens different items."/>
        <link rel="canonical" href="https://computerneek.org/graphs" />


       </Helmet>
      <div className="banner">
        <h1>Graph</h1>
      </div>
      
    
      
      <section className='graphSection'>
        <h3 id="highlight">What is a Graph</h3>
        <p>A graph is a dynamic data structure that can represent non-linear relationships between different items.</p>
        <h3 id="highlight">What does a graph consist of</h3>
        <div className="operations">
          <p><strong>Nodes<br />Edges</strong></p>
        </div>

        <h3 id="highlight">Graph</h3>
        <p>In a graph, a node is the thing that is linked, an edge is the thing that makes the connection, and a cycle is defined as a path that begins and ends at the same node. As shown in the image below. A cycle exists between nodes D, C, and E.</p>
        <img id="graphImg" src={graphImg} alt="image of a graph" />
        <h3 id="highlight">Directed Graph</h3>
        <p>A directed graph is one with arrows pointing in only one direction. Even if some nodes are bi-directional, if others are still directional, the graph is referred to as directed.</p>
        <img id="graphImg" src={directedGraph} alt="image of a directed graph" />
        <h3 id="highlight">Undirected Graphs</h3>
        <p>A bi-directional graph is an undirected graph. This is a diagram with arrow heads on both ends. They can also be depicted without arrows and just lines connecting them.</p>
        <img id="graphImg" src={undirectedGraph} alt="image of an undirected graph" />
        <h3 id="highlight">A weighted graph</h3>
        <p>A weighted graph is a graph with edges that have numerical numbers.</p>
        <img id="graphImg" src={weightedGraph} alt="picture of a weighted Graph" />
        <p>The numerical values can represent certain aspects within each node between the two. For example, it can represent the distance in meters between the nodes. The nodes in this case may represent different locations. It could also show, on a scale of 1-10, how much person A likes person B. If you were developing a social app, a weighted graph could be useful; the numbers would represent how much Person A likes Person B. It may be used by social media apps to determine which people's posts you see the most.</p>
        <h3 id="highlight">Graph Algorithm using a dictionary</h3>
        <div className="graphAccordionContainer">
          <button 
            className="graphAccordion" 
            onClick={() => togglePanel()}
          >
            <h3>Graph Algorithm Using a Dictionary</h3>
          </button>
          {openPanel &&
             <div className={style.panel}>
              <div id={style.createword1}>
      <h4>Graph Algorithm C#</h4>
      <pre className="csharp" style={{ fontFamily: 'monospace' }}>
        <span style={{ color: '#008080', fontStyle: 'italic' }}>
          //creating the dictionary for the graph
        </span>
        <br />
        <span style={{ color: '#0600FF', fontWeight: 'bold' }}>public</span>{' '}
        <span style={{ color: '#0600FF', fontWeight: 'bold' }}>static</span>{' '}
        Dictionary<span style={{ color: '#008000' }}>&lt;</span>
        <span style={{ color: '#6666cc', fontWeight: 'bold' }}>string</span>,
        Dictionary<span style={{ color: '#008000' }}>&lt;</span>
        <span style={{ color: '#6666cc', fontWeight: 'bold' }}>string</span>,{' '}
        <span style={{ color: '#6666cc', fontWeight: 'bold' }}>int</span>
        <span style={{ color: '#008000' }}>&gt;&gt;</span> friends{' '}
        <span style={{ color: '#008000' }}>=</span>{' '}
        <span style={{ color: '#008000' }}>new Dictionary</span>
        <span style={{ color: '#008000' }}>&lt;</span>
        <span style={{ color: '#6666cc', fontWeight: 'bold' }}>string</span>,
        Dictionary<span style={{ color: '#008000' }}>&lt;</span>
        <span style={{ color: '#6666cc', fontWeight: 'bold' }}>string</span>,{' '}
        <span style={{ color: '#6666cc', fontWeight: 'bold' }}>int</span>
        <span style={{ color: '#008000' }}>&gt;&gt;</span>
        <span style={{ color: '#008000' }}>&#40;</span>
        <span style={{ color: '#008000' }}>&#41;</span>
        <span style={{ color: '#008000' }}>&#59;</span>
        <br />
        <span style={{ color: '#008080', fontStyle: 'italic' }}>
          //creating a new entry in the dictionary
        </span>
        <br />
        friends<span style={{ color: '#008000' }}>.</span>
        <span style={{ color: '#0600FF', fontWeight: 'bold' }}>Add</span>
        <span style={{ color: '#008000' }}>&#40;</span>
        <span style={{ color: '#666666' }}>&quot;steve&quot;</span>,{' '}
        <span style={{ color: '#008000' }}>new</span> Dictionary
        <span style={{ color: '#008000' }}>&lt;</span>
        <span style={{ color: '#6666cc', fontWeight: 'bold' }}>string</span>,{' '}
        <span style={{ color: '#6666cc', fontWeight: 'bold' }}>int</span>
        <span style={{ color: '#008000' }}>&gt;</span>
        <span style={{ color: '#008000' }}>&#40;</span>
        <span style={{ color: '#008000' }}>&#41;</span>
        <span style={{ color: '#008000' }}>&#41;</span>
        <span style={{ color: '#008000' }}>&#59;</span>
        <br />
        adding values to the key <span style={{ color: '#666666' }}>
          &quot;steve&quot;
        </span>
        <br />
        friends<span style={{ color: '#008000' }}>&#91;</span>
        <span style={{ color: '#666666' }}>&quot;steve&quot;</span>
        <span style={{ color: '#008000' }}>&#93;</span>
        <span style={{ color: '#008000' }}>.</span>
        <span style={{ color: '#0600FF', fontWeight: 'bold' }}>Add</span>
        <span style={{ color: '#008000' }}>&#40;</span>
        <span style={{ color: '#666666' }}>&quot;andrae&quot;</span>, score
        <span style={{ color: '#008000' }}>&#41;</span>
        <span style={{ color: '#008000' }}>&#59;</span>
        <br />
        friends<span style={{ color: '#008000' }}>&#91;</span>
        <span style={{ color: '#666666' }}>&quot;steve&quot;</span>
        <span style={{ color: '#008000' }}>&#93;</span>
        <span style={{ color: '#008000' }}>.</span>
        <span style={{ color: '#0600FF', fontWeight: 'bold' }}>Add</span>
        <span style={{ color: '#008000' }}>&#40;</span>
        <span style={{ color: '#666666' }}>&quot;emma&quot;</span>, score
        <span style={{ color: '#008000' }}>&#41;</span>
        <span style={{ color: '#008000' }}>&#59;</span>
        <br />
        &nbsp;
        <br />
        &nbsp;
      </pre>
    </div>
           </div>
          
          }
         
        </div>

        <h3 id="highlight">Adjacency matrix</h3>
        <div className="nodesanded">
          <br /><br />
          <p><strong>Edges:</strong> {"{(A,B),(B,A) (A,D),(D,A),(B,C),(C,B)(C,D),(D,C)(C,E),(E,C)(E,D)}"}</p>
          <br />
          <p><strong>Nodes:</strong> {"{A,B,C,D,E)}"}</p>
          <br />
          <p><strong>Edges with weights:</strong> {"{(A,B,1),(B,A,1) (A,D,9),(D,A,9)(B,C,4),(C,B,4),(D,C,4)(C,D,4),(C,E,3)(E,C,3),(E,D,3),(D,E,3)}"}</p>
        </div>
        <p>The edges and edges with weights shown above have two of each node, with the starting nodes switched. This is due to the fact that it is an undirected graph; in a directed graph, you would only enter one of the pairs.</p>
        <p>The image below is an adjacency matrix of the graph above, which shows how weights and nodes are connected. The adjacency matrix can also be composed of only 0s and 1s. In that case, the adjacency matrix would represent which nodes are connected rather than the weights. In that case the number 1 would indicate that they are linked and the number 0 would show that they are not linked.</p>
        <img id="imgM" src={adjancyMatrix} alt="A picture of an Adjacency matrix" />
        <p>The matrix is read from column to row. Node A to B has a weight of 1. The weight of Node A to B is one. Nodes B to C have a weight of 4, and so on. Because it is an undirected graph, the matrix displays both pairs of nodes with weights, e.g. (B,A) and (A,B). You would only show one of them if it were a directed graph.</p>
        <h3 id="highlight">Adjacency List</h3>
        <img id="graphImg" src={adjancyList} alt="A picture of an Adjacency List" />
        <p>This is another method of displaying weighted graphs. The nodes on the left are connected with the corresponding weights to those on the right.</p>
        <h3 id="highlight">The Pro and Con of the matrix</h3>
        <p><strong>Pro -</strong> Adding new edges and nodes to the adjacency matrix is simple.</p>
        <p><strong>Con</strong> - It's bad for sparse graphs as it leaves a lot of space. In an algorithm, this would mean memory wasted. The bigger the graph, the worse it becomes.</p>
        <h3 id="highlight">Pros and Cons of the adjacency list.</h3>
        <p><strong>Pro -</strong> It's good for sparse graphs since not as much space is left compared to the adjacency matrix. In an algorithm, it would be memory efficient.</p>
        <p><strong>Con -</strong> It's not as easy to read as the adjacency matrix.</p>
        <p id="indent">To learn about graph Traversing click <a href="graph-traversals.html">here</a> or to read about different topics, click <a href="programmingtheory.html">here</a></p>
      </section>
    </>
  );
};
