import React from 'react';
import styles from '../../css/GraphsTrav.module.css'; // Update to use CSS Module
import graphImg from '../../img/graphtrav/examplegraph.png';
import graphWithStackImg from '../../img/graphtrav/stack1.png';
import graphWithStackImg2 from '../../img/graphtrav/stack2.png';
import graphWithStackImg3 from '../../img/graphtrav/stack3.png';
import graphWithStackImg4 from '../../img/graphtrav/stack4.png';
import graphWithQueueImg from '../../img/graphtrav/queue1.png';
import graphWithQueueImg2 from '../../img/graphtrav/queue2.png';
import graphWithQueueImg3 from '../../img/graphtrav/queue3.png';
import graphWithQueueImg4 from '../../img/graphtrav/queue4.png';
import graphWithQueueImg5 from '../../img/graphtrav/queue5.png';
import graphWithQueueImg6 from '../../img/graphtrav/queue6.png';
import { Helmet } from 'react-helmet';
export const GraphTrav = () => {
  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://computerneek.org/graphTrav" />

      </Helmet>
      <div className={styles.banner}>
        <h2>Graph Traversals</h2>
      </div>
      <section className={styles.graphTravSection}>
        <h3 id={styles.highlight}>What is graph traversing</h3>
        <p>A way of visiting different nodes on a graph. There are two types, Depth first (using a stack) and breadth first (using a queue)</p>
        <h3 id={styles.highlight}>Depth first Traversal</h3>
        <img className={styles.graphTravImg} src={graphImg} alt="image of a graph"/>
        <p>First, push A onto the stack and mark it as visited. Then Push B onto the stack and mark it as visited (you choose from either B or D).</p>
        <img className={styles.graphTravImg} src={graphWithStackImg} alt="image of a stack"/>
        <p>Next, you push C to the stack and mark it as visited</p>
        <img className={styles.graphTravImg} src={graphWithStackImg2} alt="image of a stack"/>
        <p>You then push E to the stack (you can choose E or D).</p>
        <img className={styles.graphTravImg} src={graphWithStackImg3} alt="image of a stack"/>
        <p>Because node E has no neighbors, we remove it from the stack and see if node C has any neighbors that haven't already been visited. Node D has not been visited, so it is pushed to the top of the stack.</p>
        <img className={styles.graphTravImg} src={graphWithStackImg4} alt="image of a stack"/>
        <p>D has no neighbors, so it is popped. C has no unvisited neighboring nodes, so it is popped, as are B and A. The stack is now empty, and the search is finished.</p>
        <h3 id={styles.highlight}>Breadth first</h3>
        <img className={styles.graphTravImg} src={graphImg} alt="image of a graph"/>
        <p>First, we enqueue (add) the a node onto the queue which is A.</p>
        <img className={styles.graphTravImg} src={graphWithQueueImg} alt="image of a queue"/>
        <p>Next, we visit all of its neighboring nodes and enqueue them onto the queue.</p>
        <img className={styles.graphTravImg} src={graphWithQueueImg2} alt="image of a queue"/>
        <p>Since there are no more neighboring nodes, A is dequeued (removed) from the queue. It's now been visited. Remember it’s first in first out (FIFO) with queues.</p>
        <img className={styles.graphTravImg} src={graphWithQueueImg3} alt="image of a queue"/>
        <p>Now you enqueue all the connecting nodes of B.</p>
        <p>B has been dequeued because it no longer has any connected nodes. D is also dequeued because there are no connecting nodes.</p>
        <img className={styles.graphTravImg} src={graphWithQueueImg4} alt="image of a queue"/>
        <p>Finally, we enqueue all of node C's neighbors. E is enqueued.</p>
        <img className={styles.graphTravImg} src={graphWithQueueImg5} alt="image of a queue"/>
        <p>Since E has no neighboring nodes, C is dequeued along with E since it too has no neighbors that haven't already been visited.</p>
        <img className={styles.graphTravImg} src={graphWithQueueImg6} alt="image of a queue"/>
      </section>
    </>
  );
}
