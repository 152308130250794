import React from 'react';
import style from '../../css/programming.css'
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
export const Programming = () => {
  return (
    <>
      <Helmet>
        <meta name="keywords" content="past paper b, AQA computer science 2019, aqa computer science,past paper section b, GCE Advanced Level, GCSE, Data Structures, Algorithms, Programming, aqa a level computing, aqa a level computing, Programming Examples, , aqa alevel computer science spec "/>
        <meta name="description" content="Programming techniques in computer science"/>
        <link rel="canonical" href="https://computerneek.org/programming" />



      </Helmet>
      <div className="banner">
        <h2 id="aqah2">AQA A-Level Computing Paper 1 Section B</h2>
        <nav id="programmingNavBar">
          <ul id="programmingNavBarContainer">
            <li>
              <a id="highlight" href="#WTE">What to expect</a>
            </li>
            <li>
              <a id="highlight" href="#WTP">What to practice</a>
            </li>
            <li>
			<Link id="highlight"  to={'/pastB'}>Past Section B Solutions</Link>
            </li>
          </ul>
        </nav>
      </div>
      <section id="programmingSection">
        <div className="WTE">
          <h3 id="WTE">What to expect</h3>
          <p>
            <strong>
              Although this is specific to AQA A-Level Computing Section B of paper 1, it's still good practice for people with other exam boards.
            </strong> 
            Section B of paper 1 on the AQA Computer Science A-Level tests your ability to program. Students often think that you have to be a programming genius to get high marks on this section, but that is not necessarily the case. While having a broad range of programming skills is advantageous, you only need to be proficient in a small number of skills to perform well in this area.
          </p>
        </div>
        <div className="WTP">
          <h3 id="WTP">What to practice</h3>
          <ol id="learnList">
            <li><a href="#loops">Loops (for, foreach, and while loops)</a></li>
            <li><a href="#splitHead">Splitting strings by using the .Split() and finding the frequency of a character</a></li>
            <li><a href="#Length">Finding the length of a string (.Length)</a></li>
            <li><a href="#modulus">Conversions (int to string, string to char, etc.)</a></li>
            <li><a href="#ListsAndArrays">Using lists and arrays, and converting between them</a></li>
            <li><a href="#contains">Using .Contains()</a></li>
            <li><a href="#max">Using .Max() and .Min()</a></li>
            <li><a href="#modulus">Modulus</a></li>
          </ol>
          <p>
            The most important thing to keep in mind is that the examiner doesn't care how you finish the code as long as you answer the questions. In other words, adopting sophisticated tactics or challenging alternatives won't earn you any extra marks, so why not just choose the simplest solution? This is the best approach, especially since you only have 20 minutes to finish it. Built-in methods like .Split() in C# can save you a huge amount of time. The following examples use C#.
          </p>

          {/* Loops */}
          <h4 id="loops">For, Foreach, and While Loops</h4>
          <p>
            Many questions involve manipulating a user's input, such as counting how many times a specific character appears. In most cases, you will use a loop to iterate through the user's input. It's advisable to be proficient in using loops and related functions like .Length in a for loop statement in C#. You should also be familiar with index positions.
          </p>
          <pre className="csharp" style={{ fontFamily: 'monospace' }}>
            <code>
              Console.<span style={{ color: '#007788' }}>WriteLine</span>(<span style={{ color: '#FF0000' }}>"Enter input"</span>);
              <br />
              string input = Console.<span style={{ color: '#007788' }}>ReadLine</span>();
              <br />
              // using a for loop
              <br />
              for(int i = 0; i &lt; input.<span style={{ color: '#007788' }}>Length</span>(); i++)
              <br />
              {'{'}
              <br />
              &nbsp;&nbsp;if(input[i] == 'a')
              <br />
              &nbsp;&nbsp;{'{'}
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;Console.<span style={{ color: '#007788' }}>WriteLine</span>(<span style={{ color: '#FF0000' }}>"Your input contains the letter a"</span>);
              <br />
              &nbsp;&nbsp;{'}'}
              <br />
              {'}'}
              <br />
              &nbsp;
              <br />
              // using a foreach loop
              <br />
              foreach(char ch in input)
              <br />
              {'{'}
              <br />
              &nbsp;&nbsp;if(ch == 'a')
              <br />
              &nbsp;&nbsp;{'{'}
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;Console.<span style={{ color: '#007788' }}>WriteLine</span>(<span style={{ color: '#FF0000' }}>"Your input contains the letter a"</span>);
              <br />
              &nbsp;&nbsp;{'}'}
              <br />
              {'}'}
            </code>
          </pre>
          <p>
            An aspect of strings that people don't typically know is that you can access a specific index of a string without looping through it or converting it into an array or list.
          </p>
          <pre className="csharp" style={{ fontFamily: 'monospace' }}>
            <code>
              Console.<span style={{ color: '#007788' }}>WriteLine</span>(<span style={{ color: '#FF0000' }}>"Enter a word"</span>);
              <br />
              // Store the user's input
              <br />
              string input = Console.<span style={{ color: '#007788' }}>ReadLine</span>();
              <br />
              // Output the character at index 2
              <br />
              Console.<span style={{ color: '#007788' }}>WriteLine</span>(input[2]);
            </code>
          </pre>
          <p>
            When using while loops, it's common practice to create a boolean variable and set it to false once the loop's conditions are met.
          </p>
          <pre className="csharp" style={{ fontFamily: 'monospace' }}>
            <code>
              bool correct = true;
              <br />
              // Loop until correct is false
              <br />
              while(correct)
              <br />
              {'{'}
              <br />
              &nbsp;&nbsp;if(1 != 2)
              <br />
              &nbsp;&nbsp;{'{'}
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;// Stop the loop
              <br />
              &nbsp;&nbsp;&nbsp;&nbsp;correct = false;
              <br />
              &nbsp;&nbsp;{'}'}
              <br />
              {'}'}
            </code>
          </pre>

          {/* .Split() */}
          <h4 id="splitHead">.Split()</h4>
          <p>
            The .Split() method is also a useful tool. You can ask for user input, store it in a string variable, and then split the string wherever a specific character appears (like a space or a dash). This is stored in a string array.
          </p>
          <pre className="csharp" style={{ fontFamily: 'monospace' }}>
            <code>
              Console.<span style={{ color: '#007788' }}>WriteLine</span>(<span style={{ color: '#FF0000' }}>"Enter input"</span>);
              <br />
              // Store the user's input
              <br />
              string input = Console.<span style={{ color: '#007788' }}>ReadLine</span>();
              <br />
              // Split the input by spaces and store in strArr
              <br />
              string[] strArr = input.<span style={{ color: '#007788' }}>Split</span>(' ');
              <br />
              foreach(string item in strArr)
              <br />
              {'{'}
              <br />
              &nbsp;&nbsp;Console.<span style={{ color: '#007788' }}>WriteLine</span>(item);
              <br />
              {'}'}
            </code>
          </pre>
          <p>
            You can also use .Split('c') to find out how many times a character appears in a string.
          </p>
          <pre className="csharp" style={{ fontFamily: 'monospace' }}>
            <code>
              Console.<span style={{ color: '#007788' }}>WriteLine</span>(<span style={{ color: '#FF0000' }}>"Input text"</span>);
              <br />
              // Store user's input
              <br />
              string input = Console.<span style={{ color: '#007788' }}>ReadLine</span>();
              <br />
              int freq;
              <br />
              foreach(char c in input)
              <br />
              {'{'}
              <br />
              &nbsp;&nbsp;// Store the number of times the letter occurs
              <br />
              &nbsp;&nbsp;freq = input.<span style={{ color: '#007788' }}>Split</span>(c).<span style={{ color: '#007788' }}>Length</span>() - 1;
              <br />
              &nbsp;&nbsp;Console.<span style={{ color: '#007788' }}>WriteLine</span>(c + <span style={{ color: '#FF0000' }}>" "</span> + freq);
              <br />
              {'}'}
            </code>
          </pre>

          {/* .Length */}
          <h4 id="Length">.Length</h4>
          <p>
            The .Length property is used to find the number of elements in an array or the number of characters in a string. This is particularly useful when iterating over arrays or strings using loops.
          </p>
          <pre className="csharp" style={{ fontFamily: 'monospace' }}>
            <code>
              Console.<span style={{ color: '#007788' }}>WriteLine</span>(<span style={{ color: '#FF0000' }}>"Enter a word"</span>);
              <br />
              // Store the user's input
              <br />
              string input = Console.<span style={{ color: '#007788' }}>ReadLine</span>();
              <br />
              // Find the length of the input
              <br />
              int length = input.<span style={{ color: '#007788' }}>Length</span>;
              <br />
              Console.<span style={{ color: '#007788' }}>WriteLine</span>(<span style={{ color: '#FF0000' }}>"The length of the input is: "</span> + length);
            </code>
          </pre>
          <p>
            Remember, .Length is a property, not a method, so it doesn't require parentheses. It's often used in conjunction with loops to avoid out-of-bound errors.
          </p>
        </div>
      </section>
    </>
  );
};
