const questionsData = {
    "questions" :{
        "How many bits are in a nibble?" : "4 bits",
    "How many colour combinations are in 4 bits?" : "2^4 = 16. 16 different colour combinations.",
    "What is Opcode in machine code?" : "The portion of the instruction that specifies the operation that needs to be performed.",
    "What Operand in machine code?" : "Data or address used to operate the instruction.",
    "What is meant by the term Stored Program Concept?" : "When data and instruction are stored in the same memory location",
    "What type of architecture uses the Stored Program Concept?" : "The Von Neumann architecture",
    "What is the formula for working out the file size of an image with the results in bits?" : "(Colour depth x Height x Width) / 8",
    "What is the formula for working out the number of pixels in an image?" : "Height x Width",
    "What is the formula for working out the resolution of an image?" : "Height x Width",
    "What is a vector image?" : "Images created using a sequence of commands or mathematical statements.",
    "What are the advantages of vector images" : "They are scalable which ensures consistent quality if the image is enlarged. Modification on a small scale is easier. Supports layering in graphics packages.",
    "What are the disadvantages of vector images" : "A small error in a vector graphic graphic is obvious if the image is enlarged. They can't display detailed image properties like a bitmap image.",
    "What is error checking in computer science" : "When checking if bits have been corrupted by the interface during transmission and that they have been received.",
    "What is a parity bit?" : "Checks if there is an error in a sequence of bits",
    "What is the benefit of using hex to represent different colours?" : "It is shorter and easier to remember as well as easier to read.",
    "What is meta data?" : "Information about images that is stored within the image itself.",
    "What are instruction sets?" : "Commands for the computer",
    "What is direct addressing?" : "When the operand of the instruction refers directly to a location in memory.",
    "In order to process data, a sequence of operations are frequently required. As rach of these operations are executed, where are the results stored?" : "In the general purpose registers",
    "What are the advantages of Solid state drives(SSD)?" : "Solid with no moving parts. Almost twice as fast as loading up and copying files than HDD. They are also silent.",
    "How is data stored on a cd" : "Lazer beams etches pits which represents the binary number 0 and the lands(the areas that have not been etched on) represents the number 1",
    "What is a disadvantage to a 1D barcode?" : "It's only able to hold a limited amount of information",
    "What is an advantage to a 2D barcode?" : "It can encode more complex data.",
    "How does a camera read a barcode?" : "Decodes the 1D or 2D barcode",
    "How do laser scanners work?" : "Laser is reflected of a moving mirror allowing the barcode to be read in many different positions.",
    "How does a barcode scanner work?" : "The barcode emits a laser light and detects the reflected light off the barcode. The white columns reflect laser light which corresponds to a 0 and the black columns do not reflect light which corresponds to a 1.",
    "Why is parity checking in a scanner useful?" : "It helps to determine which way round the scanner has to read the barcode.",
    "How do pen style readers work? " : "The diode in the pen measures the light intensity that is reflected back. This generates a wave form. The dark coulombs absorb light and the white coulombs   reflect it. The wave form is then translated into a digital format.",
    "What are Cardinal numbers?" : "They are normal numbers we use to count.",
    "What are Ordinal numbers?" : "sequence of numbers in a numerical or alphabetical order.",
    "What are rational numbers?" : "Numbers that can be put into a fraction",
    "What are irrational numbers?" : "Numbers that can't be put in a fraction",
    "What are real numbers?" : "Numbers that are both rational and irrational",
    "What are the advantages of bitmap images?" : "Composed of blocks of pixels making it easy to edit. It's easy to compress a bitmap image into a smaller size",
    "What are the disadvantages of bitmap images?" : "File size can be very large. Not scalable as quality degrades when zoomed in.",
    "What is the principle of an ADC?" : "The Analogue signal sampled at regular intervals reference to a graph. The amplitude of the wave is measured. The measurement is coded into a fixed number of bits. ",
    "What is an advantage of open source software?" : "There is greater scrutiny on the code. Other programmers can learn from the code.",
    "Advantages of a CCD sensor in a camera?" : "Produces high quality images.",
    "What is the disadvantage of a CCD sensor in a camera?" : "Uses more power than a CMOS sensor",
    "What is an advantage of a CMOS sensor?" : "Uses much less power than a CCD sensor",
    "What is a disadvantage to a CCD sensor?" : "Uses much less power than a CCD sensor",
    "How does a RFID work?" : "A powered receiver emits a radio signal. The transponder the object then becomes energised by the radio waves. The transponder can now send data to the receiver.",
    "What is an underflow in programming?" : "An underflow is when a maths operation result smaller than what the device is capable of storing.",
    "What is an overflow in programming?" : "An overflow is when a maths operation result is larger than what the device is capable of storing.",
    "What's the difference between symmetric and asymmetric cipher systems?" : "Symmetric ciphers is when the same key is used.",
    "What makes One Time Pad ciphers so secure?" : " The key is truly random and that the key is used only once and then destroyed.",
    "Why would the actual file size for an image be larger than the minimum file size calculated?" : "Because the metadata will also be stored in the image.",
    "How is an image formed when taking a picture with a CMOS sensor?" : "A mosaic of red, green and blue filters are placed . The software then makes digital approximations in binary for each individual pixel based on the values of neighbouring pixels." 

    }
    
}
export default questionsData;