import React, { useState, useEffect } from 'react';
import style from '../../css/adsBlocker.css';

export const AdvertBlocker = () => {
  const [adClasses] = useState([
    "ad", "ads", "adsbox", "doubleclick", "ad-placement", 
    "ad-placeholder", "adbadge", "BannerAd"
  ]);

  useEffect(() => {
    const detect = document.querySelector("#detect");
    const wrapper = document.querySelector(".wrapper");
    const button = wrapper?.querySelector("button");

    if (!detect || !wrapper || !button) return;

    adClasses.forEach((item) => detect.classList.add(item));

    // Function to check for ad blockers or privacy tools
    const checkAdBlocker = () => {
      const getProperty = window.getComputedStyle(detect).getPropertyValue("display");
      if (getProperty === "none") {
        wrapper.classList.add("show");
      } else {
        wrapper.classList.remove("show");
      }
    };

    // Initial check
    checkAdBlocker();

    // Polling interval (e.g., every 5 seconds)
    const intervalId = setInterval(checkAdBlocker, 5000);

    button.addEventListener("click", () => {
      wrapper.classList.remove("show");
      // Optionally, you can also stop checking once whitelisted
      clearInterval(intervalId);
    });

    // Cleanup event listener and interval on component unmount
    return () => {
      button.removeEventListener("click", () => {
        wrapper.classList.remove("show");
        clearInterval(intervalId);
      });
      clearInterval(intervalId);
    };
  }, [adClasses]);

  return (
    <>
      <div id="detect"></div>
      <div className="wrapper">
        <div className="content">
          <div className="warn-icon">
            <span className="icon"><i className="fas fa-exclamation"></i></span>
          </div>
          <h2>Ad Block Detected!</h2>
          <p>Our website is made possible by displaying ads to our visitors. Please support us by whitelisting our website or allowing necessary trackers.</p>
          <div className="btn">
            <div className="bg-layer"></div>
            <button>Okay, I'll Whitelist</button>
          </div>
        </div>
      </div>
    </>
  );
};
