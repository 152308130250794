import React from 'react'
import style from '../../css/regularexp.css'
import { Link } from 'react-router-dom'
import imageSubset from '../../img/subset.png'
import unionImage from '../../img/union.png'
import { Helmet } from 'react-helmet'
export const Regular = () => {
  return (
    <>
        <Helmet>
          <link rel="canonical" href="https://computerneek.org/regular" />

        </Helmet>
        <div className="banner">
            <h1>Regular Expressions</h1>

        </div>
        <section id="regularSection">
            <h3 id="highlight">What are regular expressions</h3>
            <p>Regular expressins are series of symbols called metacharacters.</p>
            <h3>Characters to know</h3>
            <br></br>

            <div className="tableCon">
                <table className="content-table">
                <thead>
                    <td>Reference</td>
                    <td>symbol</td>
                    <td>Example</td>
                    <td>Meaning</td>
                    <td>Matching strings</td>
                </thead>
                <tbody>
                    <tr>
                    <td>1</td>
                    <td>*</td>
                    <td>xy*</td>
                    <td>Zero or more elements</td>
                    <td>x, xy, xyy, xyyy</td>
                    </tr>
                    <tr>
                    <td>2</td>
                    <td>+</td>
                    <td>x+y</td>
                    <td>One or more preceding elements</td>
                    <td>xy, xxy, xxxy, xxxxy</td>
                    </tr>
                    <tr>
                    <td>3</td>
                    <td>?</td>
                    <td>call(ing)?</td>
                    <td>Zero or one preceding element.</td>
                    <td>call, calling</td>
                    
                    </tr>
                    <tr>
                    <td>4</td>
                    <td>|</td>
                    <td>(fact) | (factor) | (factoring)</td>
                    <td>Separates alternatives like an OR operator</td>
                    <td>fact, factor, factoring </td>
                    </tr>
                    <tr>
                    <td>5</td>
                    <td>()</td>
                    <td>(d|D)om(ino|inic|inator)</td>
                    <td> Used to group together regular expressions/ precedence</td>
                    <td>Domino, Domino, dominic, Dominic, dominator, Dominator.</td>
                    </tr>

                </tbody>
                
                </table>
                
            </div>
      
      <p>{"1)"} The Example in reference 1 means that the regular expression xy*, it would match any text base string that had an x and then 0 or more y's.</p>
      <br></br>
      <p>{"2)"} The plus(+) metacharacter means one or more preceding elements. Note that with the * it allows for 0 or more but with the plus it has to include at least one. For x + y, we must have at least one x</p>
      <br></br>
      <p>{"3)"} The question mark metacharacter means we can only have zero or one preceding element. It cant have it or have it but only once.</p>
      <br></br>
      <p>{"4)"}The pipe symbol is like the OR operator and allows for the conditions presented.</p>
      <br></br>
      <p>{"5)"}The parenthesis allows to determine preference.</p>


      <br></br>
      <br></br>
      <br></br>
      <h3 id="highlight">What is a set</h3>

        <p>A set is an unordered collection of values or symbols that appears once</p>
        <p>Read below to look at the different type of sets.</p>
        <br></br>
      <br></br>
        <h3 id="highlight">Set of all integers</h3>
        <p>To represent a set of all integers(positive and negative whole numbers), we write them out as </p>
        <p>ℤ = {"{-3,-2,-1,0,1,2,3}"}.</p>
        <br></br>
        <br></br>
        <h3 id="highlight">Empty Sets</h3>
        <p>To represent an empty set you just use an empty curly braces { } or a the character ∅. You can also write it out as ∅ = {"{ }"} but that is optional.</p>
        <br></br>
        <br></br>
        <h3 id="highlight">Infinite set</h3>
        <p>To represent an infinite set is also represented by ℕ = {"{0,1,2,3}"}.</p>
        <br></br>
        <br></br>
        <h3 id="highlight">Set of rational numbers</h3>
        <p>To represent a set of rational numbers(numbers that can be put in a fraction) its, ℚ = {"{1/4,1/6,3/5}"}</p>
        <br></br>
        <br></br>
        <h3 id="highlight">Set of real numbers</h3>
        <p>To represent a set of real numbers(numbers that can't be put in a fraction and numbers that can be put in a fraction) its ℝ= {"{-18, 2.2, 7.9,ℼ }"}.</p>
        <br></br>
        <br></br>
        <h3 id="highlight">Things to know</h3>
        <div className="thingsCon">
            <div className="thingstoknow">
              <br></br>
      
                <h4>Cardinality</h4>
                <p>Cardinality refers to the number of elements in a <strong>finate</strong> set. In the example below, it shows how you would use cardinality.</p>
                <br></br>
     
                <h4>Example</h4>
                <br></br>
                <p>The set is X</p>
                <br></br>
                <p>X = {"{45,62,56,90}"}</p>
               <br></br>
                <p>You would say that set X has a cardinality of 4.</p>
                <br></br>
                <h4>A finite set</h4>
                <p>A finate set is a set that has a limited amount of items.</p>
                <br></br>
                <h4>Infinite sets</h4>
                <p>Infinte sets, like the name suggests, are sets that have numbers that never end. ℕ,ℚ,ℤ and ℝ are all examples of infinite sets because they don't end. </p>
                <h4>Countable set</h4>
                <p>A countable set is either a set that is not infinite or a natural number set(a set with just positive numbers) that is infinite but has numbers which can be counted from one at a time</p>
                <h4>Example</h4>
                <p>N = {"{0, 1, 2, 3, 4 ...}"}</p>
                <br></br>
                <h3 id="highlight">Set Comprehension</h3>
                <p> X = {"{x&sup2 | x}"} {String.fromCharCode(8714)} {"{ℕ &#x2227 n &lt; 5}"}</p>
                <br></br>
                <p>The X{} means the set "X".</p>
                <br></br>
                <p>'|' means such that</p>
                <br></br>
                <p>'&#8714' means "belong to"</p>
                <br></br>
                <p>'ℕ' means natural numbers</p>
                <br></br>
                <p>'&#x2227' means "and"</p>
                <br></br>
                <p>Altogther, it means, <br></br> <br></br>X eauals x&sup2 such tha x belongs to the infinite set of natural numbers, including zero, and n is less than 5. <br></br> <br></br>Remeber that ℕ is the symbol for natural numbers and that it is infinite since there can be an infinite amount of positive numbers. </p>
                <br></br>
                <p>In this example it means that we have to use the natural numbers of n that is less than 5 </p>
                <br></br>
                <p>ℕ = {"{0, 1, 2, 3, 4}"}</p>
                <br></br>
                <p>and each n is going to be squared</p>
                <br></br>
                <p>B = {"{0, 1, 4, 9, 16}"}</p>
                <p>This means that set be equals 0, 1, 4, 9 and 16</p>

                <h3 id="highlight">Cartesian product of sets</h3>
                <p>The Cartesian product of two sets is the set of all ordered pairs(a, b), where a is a member of set A and b is a member of set B</p>
                <br></br>
                <p>Its written as A x B but we say it as "A cross B"</p>
                <h4>Example</h4>
                <p> C = A x B </p>
                <br></br>
                <p> A = {"{2, 5, 10}"}</p> 
                <br></br>   
                <p>B = {"{4, 14, 44}"}</p> 
                <br></br>
               <p> To form the cartesian product, its:</p>  
               <p>C = {"{(2, 4), (2, 14), (2, 44), (5, 4), (5, 44), (10, 4), (10, 14), (10, 44)}"}</p>
               <br></br>
               <p>Above, we have written each element of set A with each element of set B. To work out how many items in your cartesian product you should have, just do the number of elements in set A times the number of elements in set B.</p>  
                <br></br>
                <br></br>
                <h3 id="highlight">Subsets and proper sets</h3>
                <p>In the example below. Every member in set Y is also a member in set X, so we can say Y is a subset of X </p>
                <img id="regularImg" src={imageSubset}alt="Subsets"/>
                <p>We can write this as X &#8838; Y</p>
                <br></br>
                <p>If X is a subset of  Y but is not equal to it, we say that X is a <strong>proper</strong> set of Y</p>
                <br></br>
                <p>We write that as X &#8834; Y. </p>
                <br></br>
                <h4>Example</h4>
                <p>{"{6,7,3,2,6,8}"} &#8834; ℕ. This means that the numbers 6, 7, 3, 2, 6, 8 is a proper set of natural numbers. They are not equal to ℕ since ℕ is infinite</p>
                <br></br>
                <h3 id="highlight">Union</h3>
                <p>By using union, we are able to append all the contents of one set to another</p>
                <br></br>
                <h4>Example</h4>
                <br></br>
                <p> X = {"{4, 2,6}"} </p>
                <br></br>
                <p>Y = {"{7, 9, 10}"}</p>
                <br></br>
                <p>X &cup; Y = {"{2, 4, 6, 7, 9, 10}"}</p>
                <img id="regularImg" src={unionImage} alt="union image"/>

                <h3 id="highlight">Intersection</h3>
                <p>Intersection set contains all the comman elements that two sets have</p>
                <br></br>
                <h4>Example</h4>
                <br></br>
                <p>X = {"{1, 7, 5, 4, 9, 0}"}</p>
                <br></br>
                <p>X = {"{12, 7, 9, 4, 13, 55}"}</p>
                <br></br>
                <p>X &cap Y = {"{4, 7, 9}"};</p>
                <br></br>
                <br></br>
                <h3 id="highlight">Compliment</h3>
                <br></br>
                <p>The difference set consisits elements in one set that are not members of the other set</p>
                <br></br>
                <p>X = {"{1, 2, 4, 8, 9}"}</p>
                <br></br>
                <p>Y = {"{1, 8, 5, 10, 12}"}</p>
                <br></br>
                <p>X \ Y = {"{4, 5, 10, 12}"}</p>
                <br></br>
                <p>This reads as everything which is in set X but not in set Y.</p>









            </div>
    
        </div>
	 <p id="indent">To read about Backus-Naur Form click <Link to={'/backus'} style={{color: 'purple'}}>here</Link> or to read about different topics, click <Link to={'/programmingTheory'} style={{color: 'purple'}}>here</Link></p>

        
        
    </section>
    

    </>
  )
}
