import React, { useEffect } from 'react';

// Helper function to load the Google Ads script
const loadGoogleAdsScript = () => {
  if (document.querySelector('script[src*="pagead2.googlesyndication.com"]')) {
    // Script is already loaded
    return;
  }

  const script = document.createElement("script");
  script.async = true;
  script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1783096288000655";
  script.crossOrigin = "anonymous";
  document.body.appendChild(script);

  // Initialize adsbygoogle once the script is loaded
  script.onload = () => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  };

  // Clean up script on unmount
  return () => {
    document.body.removeChild(script);
  };
};

export const HorizontalAds = () => {
  useEffect(() => {
    const cleanup = loadGoogleAdsScript();

    return () => {
      if (cleanup) cleanup();
    };
  }, []);

  return (
    <ins
      className="adsbygoogle"
      style={{ display: "block" }}
      data-ad-client="ca-pub-1783096288000655"
      data-ad-slot="5626172718"
      data-ad-format="auto"
      data-full-width-responsive="true"
    ></ins>
  );
};

export const SquareAds = () => {
  useEffect(() => {
    const cleanup = loadGoogleAdsScript();

    return () => {
      if (cleanup) cleanup();
    };
  }, []);

  return (
    <ins
      className="adsbygoogle"
      style={{ display: "block" }}
      data-ad-client="ca-pub-1783096288000655"
      data-ad-slot="1266989830"
      data-ad-format="auto"
      data-full-width-responsive="true"
    ></ins>
  );
};

export const VerticalAds = () => {
  useEffect(() => {
    const cleanup = loadGoogleAdsScript();

    return () => {
      if (cleanup) cleanup();
    };
  }, []);

  return (
    <ins
      className="adsbygoogle"
      style={{ display: "block" }}
      data-ad-client="ca-pub-1783096288000655"
      data-ad-slot="8471827909"
      data-ad-format="auto"
      data-full-width-responsive="true"
    ></ins>
  );
};

export default {
  HorizontalAds,
  SquareAds,
  VerticalAds,
};
