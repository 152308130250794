const questionsData = {
    "questions" : {
            "What factors affect processor performance?" : "Clock speed, Number of cores, cache size and type, Word length, address  and data bus width.",
            "What is clock speed?" : "The number of cycles that a processor carries per second",
            "What are the effects of increasing clock speed in a computer?": "The greater the clock speed increases the number of operations that can be processed thus the faster the computer will run.",
            "What is a disadvantage of having a higher clock speed?" : "The CPU can get too hot.",
            "What does the CPU in a computer do?" : "Fetches, decodes and executes instructions and performs logical and arithmetic operations.",
            "Which buses are bidirectional?" : "The data and the control bus.",
            "What affects how well a bus operates?" : "A thicker bus width means greater amounts of addresses, data and signals can be sent.",
            "What are the advantages of optical storage?" : "It's cheap per GB of storage and is portable.",
            "What are the disadvantages of optical storage?" : "It's  easy to break/scratch. It has slow read/write speeds. ",
            "What are the advantages of a magnetic hard drive?" : "Cheap per GB of storage. It has medium read/write speed. It also has a high capacity of storage,",
            "What are the disadvantages of a magnetic hard drive?" : "Not very portable and can be damaged if dropped. It will slow down eventually if files get fragmented.",
            "What is Moore's Law?" : "Moore's Law states that the number of transistors on a processor doubles about every 2 years.",
            "What will eventually happen if the effects of Moore's law occur?" : "A physical limit will be reached where there will be so many transistors that they produce too much heat.",
            "Why is virtual memory slower than RAM?" : "Because processing power is being taken up by moving data around rather than executing instructions.",
            "What does a heat sink do?" : "Conducts heat which allows the fan to blow it away.",
            "What is a MAC address" : "The unique identifier on a NIC. ",
            "How do switches know which devices are the devices which need to receive data?" : "The switch stores all the MAC addresses for all the devices and directs the data to the device with that mac address.",
            "What are the differences between Havard and Von Numan's CPU?" : "Havards's CPU is faster as you can receive data and instructions due to them each having their own memory. This also makes Havard's CPU more expensive. Von Numman's CPU is error prone as data and instruction can be mistaken for each other.",
            "What is the purpose of cache?" : "To store frequently used data and instructions.",
            "What is virtual memory?" : "A temporary volatile memory space created when RAM is full.",
            "What is processor scheduling?": "Allocates time slices for different applications.",
            "What does the ALU do?" : "Stores arithmetic and logic operations.",
            "What does the control unit do?" : "Regulates and integrates the operations of the computer.",
            "What is the role of the accumulator?" : "Short-term register that stores arithmetic and logic operations.",
            "What is the role of the program counter?" : "Holds the address of the next instruction to be processed.",
            "How does solid state drives save data?" : "Millions of transistors are either on or off and remain so until electricity is applied to the system. SSD's use switches called floating gate transistors. These create a special cage that traps the electron flow within it. This gate effect continues whether there is electricity in the system or not and so storage remains when power is removed.",
            "What are the advantages of a magnetic hard drive over an SSD?" : "It has more storage capacity. Magnetic hard drives are also cheaper per unit storage than SSD. Data can also be overwritten directly onto the disk with magnetic hard drive.",
            "What are 3 hardware components of a solid state drive?": "Controller, NAND gate and flash memory.",
            "What is the disadvantage of an SSD?" : "More expensive per GB.",

            "What happens if you increase the amount of cores in a computer?" : "It allows for more instructions to be carried out in parallel.",
            "What will happen if you increase the cache?" : "It increases the amount of data and instructions that are frequently used which decreases latency and increases performance.",
            "What are the advantages of increasing RAM in a computer?" : "It increases the speed of which RAM transfers information to other components.",

            "What is the full name of CIR?" : "Current instruction register.",
            "How is data able to be read from a CD-R disk?" : "Cd drive shines a laser onto the surface of the disk and detects the reflective areas and bumps by the amount of laser they reflect. The drive converts reflections into 0 and 1's.",
            "What is the difference between synchronous and asynchronous communication?" : "bits in synchronous communications are scheduled whilst bits in asynchronous communication happen on its own time and do not need scheduling.",
            "What binary digit is the start bit?" : "0",
            "What binary digit is the stop bit?" : "1",
            "Why is it more efficient to store intermediate results in a general register than in main memory?" : "It's quicker as its closer to the CPU meaning that it takes less time for data to reach it.",
            "What are the differences between primary and secondary storage?" : "Primary storage contents are not saved when the computer is off. Secondary storage contents are loaded up when the computer is switched on.",
            "What is the purpose of an interrupt?" : "To check whether a software or hardware requires attention.",
            "How does the FED cycle operate?" : "The program counter(PC) copies its contents to the MAR(Memory address register). The PC then increments by 1 and holds the address of the next instruction. The processor sends a signal to the MAR via the address bus. From there its contents are copied to the MBR via the data bus. Its contents are then copied to the CIR ready for it to be decoded. From there the instruction is decoded and executed. The CIR monitors and manages the whole process. ",
            "What are the 2 types of interrupts?" : "Hardware and software interrupts."

        }
}

export default questionsData;