import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import style from '../../css/pastB.css';
import pastPaper2017 from '../../img/2017paperb.png';
import pastPaper2017Screenshot from '../../img/screenshot/2017paperb.png';
import pastPaper2018 from '../../img/2018paperb.png';
import pastPaper2018Screenshot from '../../img/screenshot/2018paperb.png';
import pastPaper2019 from '../../img/2019paperb.png';
import pastPaper2019Screenshot from '../../img/2019paperb.png';
import pastPaper2020 from '../../img/2020paperb.png';
import pastPaper2020Screenshot from '../../img/screenshot/2020paperb.png';
import { Helmet } from 'react-helmet';
export const PastB = () => {
    const [accordionState, setAccordionState] = useState({
        accordion1: false,
        accordion2: false,
        accordion3: false,
        accordion4: false,
    });

    const handleAccordionToggle = (accordionKey) => {
        setAccordionState((prevState) => {
            const newState = {
                accordion1: false,
                accordion2: false,
                accordion3: false,
                accordion4: false,
            };
            newState[accordionKey] = !prevState[accordionKey];
            return newState;
        });
    };

    return (
    <>
            <Helmet>
              <meta name="description" content="Computer Science AQA past paper section B Solutions"/>
              <meta name="keywords" content="past paper b, AQA computer science 2019, aqa computer science,past paper section b, GCE Advanced Level, GCSE, Data Structures, Algorithms, Programming, aqa alevel computer science spec, aqa a level computing, Programming Examples, Computerneek, paper 1 section b solutions "/>
              <link rel="canonical" href="https://computerneek.org/pastB" />

            </Helmet>
            <div className="banner">
                <h2>AQASection B Exam Past Papers Solutions</h2>
            </div>
            <section id="pastB">
                <div id="select">
                    <h3><a href="#">Select from the list</a></h3>
                </div>
                <div className="accordioncontainer">
                    
                    <div className={accordionState.accordion1 ? "panel dontHide" : "panel hide"}>
                        <div id="lettercount">
                            <h4>AQA computer science 2017 - Paper 1 Section B in c#</h4>
                            <p>This section asked you to output how many times each letter in the users input occurs. What made this hard was the fact that if there was duplicate letters, you could only output one of those letters and the amount of times that letter occurred next to it. In essence, you must output the quantity of instances of each letter next to its initial occurrence or at least output it next to one duplicate letter. The .Split() we learnt this in the programming page.</p>
                            <br></br><p>To learn how to use .Split() in more depth click <a href="programming.html">here</a></p>
                            <p>To learn how to use .Split() in more depth click <Link to={'/programming'}>here</Link></p>
                            <img id="amountofletterspaper" src={pastPaper2017} alt="Image of 2017 AQA past paper section B" />
                            <img id="sc1" src={pastPaper2017Screenshot} alt="code for 2017 AQA paper 1 Section B" />
                        </div>
                    </div>

                    <button
                        className={accordionState.accordion2 ? "accordion active" : "accordion"}
                        onClick={() => handleAccordionToggle('accordion2')}
                    >
                        <h2>AQA2018 Section B</h2>
                    </button>
                    <div className={accordionState.accordion2 ? "panel2 dontHide" : "panel2 hide"}>
                        <div id="primenumbertitle">
                            <h4>AQA computer science 2018 - Paper 1 Section B in c#</h4>
                            <p>
                                This question essentially asks you to write a program that checks if a number is a prime number...
                            </p>
                            <br />
                            <p>To learn how to use modulus(%) in more depth click <Link to={'/programming'}>here</Link></p>
                        </div>
                        <img id="primenumber" src={pastPaper2018} alt="Image of 2018 AQA past paper section B" />
                        <img id="sc2" src={pastPaper2018Screenshot} alt="code of the 2018 AQA paper 1 section" />
                    </div>

                    <button
                        className={accordionState.accordion3 ? "accordion active" : "accordion"}
                        onClick={() => handleAccordionToggle('accordion3')}
                    >
                        <h2>AQA2019 Section B</h2>
                    </button>
                    <div className={accordionState.accordion3 ? "panel3 dontHide" : "panel3 hide"}>
                        <div id="createword1">
                            <h4>AQA computer science 2019 - Paper 1 Section B in c#</h4>
                            <p>This question essentially asks you to write a program that enters in two words seperatly from the user and sees if the first word can be created from the letters of the second word.</p>  
                            <br></br><p>To learn how to use .Contains() in more depth click <a href="programming.html">here</a></p>
                        </div>
                        <img id="createword" src={pastPaper2019} alt="Image of 2019 AQA past paper section B" />
                        <img id="sc3" src={pastPaper2019Screenshot} alt="code of the 2019 AQA paper 1 section" />
                        <p>The image above when the .split() is used just above the the first if statement, works out how many times each char in the given string appears in that string and stores it in the variables "firstWordFreq" and "secondWordFreq". For an example, in the word meet, the letter (e) appears twice. That line recognises that it appears twice. Since we are checking if we can create the first word from letters of the sceond word, that must mean that the amount of duplicate letters in the second word ("secondWord") must be greater than or equal to the amount of same duplicate letters in the first word.</p>
                    <p> For an example, if the first letter was "good", the second letter must have at least two or more letter "o"  so that the first word can be created form the letters of the second word.  </p>
                    </div>

                    <button
                        className={accordionState.accordion4 ? "accordion active" : "accordion"}
                        onClick={() => handleAccordionToggle('accordion4')}
                    >
                        <h2>AQA2020 Section B</h2>
                    </button>
                    <div className={accordionState.accordion4 ? "panel4 dontHide" : "panel4 hide"}>
                        <div id="createword1">
                        <h4>AQA computer science 2020 - Paper 1 Section B in c#</h4>
                    <p>This question essentially asks you to write a program that finds the mode. In this example, I found it easier to use a string rather than using an int but for the purpose of the program, I still asked the user to enter in how many numbers they were entering. This suggests that they were probably expecting you to use a for loop but this way stille works.</p>    
                    <br></br><p>To learn how to use .Split() and .Contains() or .Max() in more depth click <a href="programming.html">here</a></p>
                            <img id="createword" src={pastPaper2020} alt="Image of 2020 AQA past paper section B" />
                            <img id="sc4" src={pastPaper2020Screenshot} alt="code of the 2020 AQA paper 1 section" />
                        </div>
                    </div>
                </div>

                <p id="techni">To see the techniques used in the solutions Click <Link to={'/programming'}>me</Link></p>
            </section>
        </>
    );
};
