import React from 'react'
import { Link } from 'react-router-dom'
import style from '../../css/programmingtheory.module.css'
import { Helmet } from 'react-helmet'
export const ProgrammingTheory = () => {
  return (
   <>
        <Helmet>
            <link rel="canonical" href="https://computerneek.org/programmingTheory" />

        </Helmet>
        <div className="banner">
            <h2>Programming Theory Topics</h2>

        </div>
        <section id={style.programmingTheorySection}>
            <div className="firsthalf">
                <h4>
                    <Link className={style.link-style} to={'/stack'}>Stack</Link>
                </h4>
                <h4>
                    <Link className={style.link-style} to={'/queues'}>Queues</Link>
                </h4>
                <h4>
                    <Link className={style.link-style} to={'/Graphs'}>Graph</Link>
                </h4>
                <h4>
                    <Link className={style.link-style} to={'/treesandbinary'}>Trees and Binary Trees</Link>
                </h4>
                <h4>
                    <Link className={style.link-style} to={'/dictionaries'}>Dictionaries</Link>
                </h4>
                <h4>
                    <Link className={style.link-style} to={'/graph-traversals'}>Graph Traversals</Link>
                </h4>
                
            </div>
       
            <div className="secondhalf">
                <h4>
                    <Link className={style.link-style} to={'/tree-traversal'}>Tree Traversals</Link>
                </h4>
                <h4>
                    <Link className={style.link-style} to={'/reverse-polish-notation'}>Reverse Polish Notation</Link>
                </h4>
                <h4>
                    <Link className={style.link-style} to={'/sortingAlgoritm'}>Sorting and Searching Algorithms</Link>
                </h4>
                <h4>
                    <Link className={style.link-style} to={'/bgo'}>Big-O-Notation</Link>
                </h4>
                <h4>
                    <Link className={style.link-style} to={'/regular'}>Regular Expressions</Link>
                </h4>
                <h4>
                    <Link className={style.link-style} to={'/backus'}>Backus-Naur Form</Link>
                </h4>

            </div>

    </section>
    <p id={style.para}>Covers AQA, CIE, OCR, Eduqas, and WJEC exam specifications .</p>

   </>
  )
}
