import { Outlet,Link } from "react-router-dom"
import { Header } from "../header"
import { Footer } from "../footer"
import { AdvertBlocker } from "../advertBlocker"
import { FreelanceWeb } from "../freelanceWeb"
const Layout = () => {
    return (
        <>
            <AdvertBlocker/>
            <Header/>


            <main className="App">
                 <Outlet />

            </main>
            
            <Footer/>

        </>
    )
}

export default Layout
