import React from 'react'
import style from '../../css/queues.module.css'
import imgQueue from '../../img/staticqueue.png'
import imgQueue2 from '../../img/staticqueue2.png'
import imgCircularQueue from '../../img/circularqueue.png'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
export const Queues = () => {
  return (
    <>
        <Helmet>
          <link rel="canonical" href="https://computerneek.org/queues" />

        </Helmet>

        <div className={style.banner}>
            
            <h1>Queues</h1>
        </div>

        <section className={style.sectionQueue}>
        
            <h3 id={style.highlight}>What is a Queue</h3>
            <p>A queue in computer science is a collection of items that are added from the bottom to the top and removed from the bottom to the top. This is commonly referred to as first in first out<strong>(FIFO)</strong>.</p>
            <h3 id={style.highlight}>There are five operations of a stack </h3>
            <div className="operations">
                <p><strong>enqueue(data) - add data<br></br>dequeue() - remove data <br></br>is_empty()<br></br>is_full()</strong></p>
            </div>
            <h3 id={style.highlight}> Queues</h3>
            <p>Arrays are commonly used to operate queues. Assume we have an array with a size that can hold three items and we <strong>enqueue</strong>()(add) three items. Item 1 will have the name "Steve," item 2 will have the name "Julian," and item 3 will have the name "Sarah." <br></br>Steve will be at the front of the queue because he was the first item added. Sarah would be at the back of the queue because she was the last person to be added to it. The front of the Queue has the value 1 because it is the first item in the queue, and the back has the value 3 because it is the last item added to the queue.</p>
            <img id={style.img} src={imgQueue} alt="image of a queue"/>
            <p>If you were to <strong>dequeue()</strong> an item from this queue, steve would be removed and the front would increment. The rear would still be Sarah at 3.</p>
            <img id={style.img} src={imgQueue2} alt="image of a priority queue"/>
            <h3 id={style.highlight}>Priority Queue</h3>
            <p>A priority Queue differs slightly in that each item in the queue has a priority. When we add new items to the queue, if they have a higher priority than some of those already in the queue, they are placed ahead of those with a lower priority. A hierarchical system is an example. A  CEO may have a higher priority than the manager thus, their needs will be prioritised over the managers'. Managers' needs may be prioritised higher than those of regular employees, but not as high as those of the CEO. A way to show this may be an each person having a specific Key. If you have the letter A as a key, you have the highest priority but if you have the letter C as a key, you have a lower priority.</p>
            <h3 id={style.highlight}>Circular Queue</h3>
            <p>Consider the example under the heading <strong>Queues</strong>. The problem with the above queue is that the rear value has reached its capacity, but there is still one space available to fill the queue. We could <strong>dequeue</strong>() all of the items and then repopulate them, but this is inefficient. Circular queues are useful in this situation. In circular queue's when the rear has reached its maximum, it is moved to the first element of the array and the empty first element is populated from there. So, in the above example, if we wanted to add "James" to our queue, the rear would move from item 3, back to item 1, and populate that array with James. This means that the rear is now 1 and the front is still "Julian"(item 2). The next item to be dequeue() would be "Julain".</p>
            <img id={style.img} src={imgCircularQueue}alt="image of a circular queue"/>
            <p>This can also been shown as a circle</p>
            <p id={style.indent}>To read about stacks click <Link to={"/stack"} style={{color:"purple"}}>here</Link> or to read about different topics, click <Link to={"/programmingtheory"} style={{color: "purple"}}>here</Link></p>
    
        </section>
    </>
  )
}
