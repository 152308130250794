import React, { useState, useEffect } from 'react';
import style from '../../../css/compsystem.css'
import { Helmet } from 'react-helmet';
import questionsData from '../../../js/compArc';

export const CompArc = () => {
    <Helmet>
        <meta name="keywords" content="Theory, Computer Architecture, Flashcards, Flash cards, Preparation, aqa alevel computer science spec, Programming Examples, Computerneek, aqa a level computing"/>
        <meta name="description" content="Theory Computer Architecture Flashcards"/>
        <link rel="canonical" href="https://computerneek.org/compArc" />

    </Helmet>
    const { questions } = questionsData;
    
    const [orderedFlashcards, setOrderedFlashcards] = useState([]);
    const[orderFlashType, setOrderedFlashType] = useState(true);
    const [showResults, setShowResults] = useState(false);
    const [currentCardIndex, setCurrentCardIndex] = useState(0);
    const [cardFlipCount, setCardFlipCount] = useState(false);
    const [flip, setFlip] = useState(false);
    const [viewAccordian, setViewAccordian] = useState(false);
    const [randomFlashCards, setRandomFlashCards] = useState([]);
    const[nextRandomButtonClicked, setNextRandomButtonClicked] = useState(false)


    useEffect(() => {
       
        // Populate the flashcards only if they haven't been set yet
        if (orderedFlashcards.length === 0) {
            const flashcards = Object.entries(questions).map(([question, answer]) => ({
                question,
                answer,
            }));
            setOrderedFlashcards(flashcards);
        }
    }, [orderedFlashcards]);
   

    function shuffle() {
        
        setRandomFlashCards(orderedFlashcards.sort(() => Math.random() - 0.5));
    }

    const onClickOrdered = () => {
        setShowResults(true);
        setCardFlipCount(true);
        setOrderedFlashType(true)

    };
    const onClickRandom = () => {
        setShowResults(true);
        setCardFlipCount(true);
        setOrderedFlashType(false)
        shuffle();


    };
    const randomNextButtonClicked = () =>{
        setNextRandomButtonClicked(!nextRandomButtonClicked);

    }

    const handleNextClick = () => {
        setNextRandomButtonClicked(true);
        // Move to the next card, or loop back to the first card if at the end
        setCurrentCardIndex((prevIndex) => 
            prevIndex === orderedFlashcards.length - 1 ? 0 : prevIndex + 1
        );
    };

    const handlePreviousClick = () => {
        // Move to the previous card, or loop back to the last card if at the beginning
        setCurrentCardIndex((prevIndex) => 
            prevIndex === 0 ? orderedFlashcards.length - 1 : prevIndex - 1
        );
    };

    const handleCardClick = () =>{
        setCardFlipCount(false);
        
        setFlip(!flip)
        
    
        

    }
    const handleAccordianClick = () =>{
        setViewAccordian(!viewAccordian);

    }
 
    

    return (
        <div className="donateandmaincon">
            <div className="banner">
                <h2 id="bannerTitle">Flashcards</h2>
                <p style={{fontSize: "1.2em"}}>{cardFlipCount ? 'Click card to flip' : ''} </p>
            </div>
            <div align="centre" className="sidenav"></div>

            
            <section>

                <div className={showResults ? "hide" : "dontHide"}>
                    <div className="lesserHide">
                        <div className="buttons">
                            <button id="probuttons1" onClick={onClickOrdered}>
                                Ordered Flashcards - Computer Systems
                            </button>
                            <button id="probuttons2" onClick={onClickRandom}>
                                Randomised Flashcards - Computer Systems
                            </button>
                        </div>
                    </div>
                </div>


                <div className={showResults ? "dontHide" : "hide"}>
                    <div className="mainHide">
                        <p id="completed"> {}</p>
                        <p id="number">
                                {orderFlashType ? `${currentCardIndex + 1}/ ${orderedFlashcards.length}` : currentCardIndex === 38 ? "Flash cards completed. Reload the page to start again" : ""}

                        </p>
                        <div className="scene scene">
                            
                            <div className= {`card ${flip ? 'cardFlip': ''}`} >
                                
                                <div className="card__face card__face--front" onClick={handleCardClick}>
                                    <div id="spacing">
                                        <h4 id="front">{ orderFlashType? orderedFlashcards[currentCardIndex]?.question : randomFlashCards[currentCardIndex]?.question }</h4>
                                    </div>
                                </div>
                                <div className="card__face card__face--back" onClick={handleCardClick}>
                                    <div id="spacing">
                                        <p id="back">{orderFlashType? orderedFlashcards[currentCardIndex]?.answer : randomFlashCards[currentCardIndex]?.answer }</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className= {orderFlashType ? "nextbuttons" : currentCardIndex < 38 ? "nextbuttons" : "hide"}>
                            
                            <div className={orderFlashType ? "hide" : "dontHide"}>
                                <button id="next2" onClick={handleNextClick }>Next</button>
                            </div>
                            <div className={orderFlashType ? "dontHide" : "hide"}>
                                <div className="backandNext">
                                    <button id="previous" onClick={handlePreviousClick}>&larr;</button>
                                    <button id="next" onClick={handleNextClick}>&rarr;</button>
                                </div>

                            </div>
                            
                            
                           
                            
                        </div>

                        <div className= {orderFlashType ? "accordianCon" : 'hide' } style={{marginBottom: '100px', marginTop: '40px'}}>
                            <button className="accordion" onClick={handleAccordianClick}>
                                <h2>Click to View Questions</h2>
                            </button>
                            <div  className={viewAccordian? 'dontHide' : 'hide'}>
                                <div className="panel">
                                    {orderedFlashcards.map((card, index) => (
                                        <div key={index} className="individualQ">
                                            <div className="uniqueQues">
                                                <div className="cardQuestion">
                                                    <p className="ques">{card.question}</p>
                                                </div>
                                                <div className="cardAnswer">
                                                    <p className="ans">{card.answer}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                            </div>
                           
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};
