import React from 'react'
import style from '../../css/trees.module.css'
import tree from '../../img/tree.png'
import binaryTree from '../../img/binarytree.png'
import { Helmet } from 'react-helmet'
export const TreesAndBinary = () => {
  return (
    <>
        <Helmet>
          <link rel="canonical" href="https://computerneek.org/treesandbinary" />

        </Helmet>
        <div className="banner">
            <h2>Trees and Binary Trees</h2>
        </div>
        <section id={style.sectionTree}>
            <h3 id={style.highlight} >What is a tree</h3>
            <p>A graph is a dynamic data structure that can represent non-linear relationships betweens different items.</p>
            <h3 id={style.highlight}  >What does a tree consist of </h3>
            <div className={style.operations}>
                <p><strong>Root node<br></br>Child Nodes<br></br>Parent Nodes<br></br>Edges</strong></p>
            </div>
            <h3 id={style.highlight} >Trees</h3>
            <p>In a tree, a root node is the starting node, and edges are what connects the nodes together. The child nodes derive from the parent nodes. The number of edges in a tree is equal to the number of nodes - 1. <strong>E = N -1</strong>.</p>
            <img id={style.treeImg} src={tree} alt="picture of a tree data structure"/>
            <h3 id={style.highlight}>What is a Binary Tree</h3>
            <p>A binary tree is a dynamic data structure with nodes only being able to hold a maximum of two other nodes.</p>
            <h3 id={style.highlight}>Binary Trees</h3>
            <p>Binary trees are built by connecting the smaller value of a parent node to the left and the larger value to the right. The example below shows a binary tree with the numbers [9, 7, 11, 6, 8, 5, 11, 10] inserted in this order. A quick explanation is that the number 6 is smaller than the number 9 (the root node) so it moves to the left. Because the number 6 is smaller than the number 7, it will be moved to the left and placed there. The n umber ten is greater than 9, so it goes to the right, but it is less than 11, so it goes to the left. The binary tree is built in the order of the given list.</p>
            <img id={style.treeImg} src={binaryTree}alt="image of a binary tree data structure"/>
        </section>
    </>
    
  )
}
