import React from 'react'
import { Link } from 'react-router-dom'
import style from '../../css/topics.module.css'
import { Helmet } from 'react-helmet'
export const whichTopic= (topic)=>{return topic}

export const Topics = () => {
  return (
    
    <>
        <Helmet>
          <link rel="canonical" href="https://computerneek.org/topics" />

        </Helmet>
      <div className={style.topicBanner}>
            
        <h2 id={style.theoryFlash}>Theory Flashcards</h2>
      </div>
      <section id={style.topicsSection}>
        
        <div className={style.cards_wrap}>
            <div className={style.card_item}>
                <Link className={style.cardLink} to={'/allFlashcards'} onClick={whichTopic(1)}>
                  <div className={style.card_inner}>
                    <h3>All topics</h3>
                        
                  </div>
                </Link>
               
            </div>
           
            <div className={style.card_item}>
                <Link className={style.cardLink} to={'/compSystem'} onClick={whichTopic(1)}>
                  <div className={style.card_inner}>
                    <h3>Fundementals of Computer Systems</h3>
                        
                  </div>
                </Link>
               
            </div>
            <div className={style.card_item}>
              <Link className={style.cardLink} to={'/dataRep'}>
                  <div className={style.card_inner}>
                     <h3>Fundamentals of Data Representation</h3>
                        
                  </div>
              </Link>
                
            </div>
            <div className={style.card_item}>
              <Link className={style.cardLink} to={'/compArc'}>
                  <div className={style.card_inner}>
                    <h3>Fundamentals of Computer Organisation and Architecture</h3>
                        
                  </div>
              </Link>
              
            </div>
            <div className={style.card_item}>
              <Link className={style.cardLink} to={'/databaseFlash'}>
                  <div className={style.card_inner}>
                    <h3>Fundamentals of Database</h3>
                        
                  </div>
              </Link>
               
            </div>
        
           
          
            
        </div>

    </section>
    </>
  )
}
