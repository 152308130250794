import React from 'react'
import style from '../../css/rpn.css'
import imageOfStack from '../../img/rpn/stack1.png'
import stack2 from '../../img/rpn/stack2.png'
import stack3 from '../../img/rpn/stack3.png'
import tree1 from '../../img/rpn/tree1.png'
import tree2 from '../../img/rpn/tree2.png'
import tree3 from '../../img/rpn/tree3.png'
import tree4 from '../../img/rpn/tree4.png'
import tree5 from '../../img/rpn/tree5.png'
import { Helmet } from 'react-helmet'
export const ReversePolishNotation = () => {
  return (
    <>
        <Helmet>
          <link rel="canonical" href="https://computerneek.org/reverse-polish" />

        </Helmet>
        <div className="banner">
            <h2>Reverse Polish Notation</h2>

        </div>
        <section className='rpnSection'>
            <h3 id="highlight">Infix Notation</h3>
            <p><strong>A + B</strong></p>
            <p>What we humans use is infix notation. We just don't refer to it as that. W To write in infix notation, we read it from left to right. The previous example uses letters, but a more specific example would be 2 + 5. If we did 4 * 2 + 5, we would have to use bidmas/bodmas or brackets. The problem is that computers have a hard time doing this.</p>
            <h3 id="highlight">Prefix/Polish Notation</h3>
            <p>In polish notation, <strong>A + B</strong> would be replaced by <strong>+AB</strong>. This method is less vague and makes it easier for the computer to perform. The plus sign is placed between the letters A and B.</p>
            <h3 id="highlight">Postfix notation/ Reverse Polish Notation</h3>
            <p>This is the inverse of polish notation, where <strong>+AB</strong> is replaced with <strong>AB+</strong>. This is just another way of writing <strong>A + B</strong>. You can use a stack by using reverse polish notation (RPS). As an example, consider the number <strong>438+*</strong>.</p>
            <p>First you push all the numbers onto the stack until you meet an operator.</p>
            <img id="rpnImg" src={imageOfStack} alt="image of a stack"/>
            <p>When you meet the operator you pop out the item and put it to the left of the operator and pop the other one and put it to the right of the operator You then you push the result back in.</p>
            <img id="rpnImg" src={stack2} alt="image of a stack"/>
            <p>When you meet the next operator, you pop the item and put it to the left of it and pop the other operator and put it to the right of it. The result is then pushed onto the stack.</p>
            <img id="rpnImg" src={stack3} alt="image of a stack"/>
            <h3 id="highlight">Converting infix notation to reverse polish notation</h3>
            <p>When the answer is asked, it will be popped out of the stack.</p>
            <p><strong>6*(3+4)</strong></p>
            <p>To convert <strong>6*(3+4)</strong> into reverse polish notation, we use a binary tree</p>
            <p>First we start with the left hand number.</p>
            <img id="rpnImg" src={tree1} alt="image of a tree"/>
            <p>Then we start with the operator. Operators are always in the central position(the parent nodes).</p>
            <img id="rpnImg" src={tree2} alt="image of a tree"/>
            <p>Then we put the next expression.</p>
            <img id="rpnImg" src={tree3} alt="image of a tree"/>
            <p>We now separate the expressions into subtrees.</p>
            <img id="img1" src={tree4} alt="image of a tree"/>
            <img id="img1" src={tree5}alt="image of a tree"/>
            <p>You then do a post order traversal on the tree. Click <a href="tree-traversal.html">here</a> to learn how to do post order traversal. After traversing, you will get 634+*</p>
            <h3 id="highlight">Summery</h3>
            <p><strong>A + B</strong> is Infix Notation</p>
            <p><strong>+AB</strong> is prefix/polish notation</p>
            <p><strong>AB+</strong> is postfix/reverse polish notation.</p>
            <p id="indent"></p>
          
    </section>
    
    </>
  )
}
