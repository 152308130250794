import React from 'react';
import style from '../../css/backus.css';
import backusImg from '../../img/bakus-n.png';
import backusSyntaxImg from '../../img/bakus-syntax.png';
import { Helmet } from 'react-helmet';
import { HorizontalAds, SquareAds, VerticalAds } from '../ads/index';

export const Backus = () => {
  return (
    <>
      <Helmet>
        <title>Backus-Naur Form: Definition and Examples</title>
        <meta
          name="description"
          content="Learn about Backus-Naur form, its usage in computer science, and how it helps in defining clear and concise syntax for programming languages."
          
        />
        <title>Backus Nuar Form</title>
        <link rel="canonical" href="https://computerneek.org/backus" />


        <meta
          name="keywords"
          content="Backus-Naur form, computer science, syntax definition, recursion in programming, BNF examples"
        />
      </Helmet>

      <header className="banner">
        <h1>Backus-Naur Form</h1>
      </header>

      <aside className="sidenav">
        <div className="individualAd">
          <HorizontalAds />
        </div>
        <div className="individualAd">
          <VerticalAds />
        </div>
        <div className="individualAd">
          <VerticalAds />
        </div>
        <div className="individualAd">
          <SquareAds />
        </div>
      </aside>

      <main id="backusSection">
        <section>
          <h2 id="highlight">What is Backus-Naur Form?</h2>
          <article>
            <p>
              Backus-Naur form (BNF) is a notation used to formally describe the syntax of programming languages. It ensures that the language's rules are clearly defined so that a computer can easily parse the source code.
            </p>
            <p>
              Additionally,{' '}
              <a href="regular.html">regular expressions</a> are not always sufficient for defining certain languages. Therefore, Backus-Naur form serves as a robust metalanguage.
            </p>
            <img
              id="backusImg"
              src={backusImg}
              alt="Diagram illustrating Backus-Naur form"
              loading="lazy"
            />
            <h3>Example 1</h3>
            <p>&lt;DIGIT&gt; ::= 0 | 1 | 2 | 3 | 4 | 5 | 6</p>
            <p>This definition specifies that a digit can be any value from 0 to 6.</p>
            
            <h3>Example 2</h3>
            <p id="overflow">
              &lt;LETTER&gt; ::= A | B | C | D | E | F | G | H | I | J | K | L
              | M | N | O | P | Q | R | S | T | U | V | W | X | Y | Z
            </p>
            <p>This definition specifies that a letter can be any character from A to Z.</p>
            <p>&lt;WORD&gt; ::= &lt;LETTER&gt;&lt;LETTER&gt;</p>
            <p>
              This rule defines a word as two letters. For instance, "W" and "WE" are valid words, while "GOING" is not, as it consists of more than two letters.
            </p>
          </article>

          <h2 id="highlight">Recursion in Backus-Naur Form</h2>
          <article>
            <p>
              Instead of defining new rules for each letter, recursion allows us to generalize rules. Here’s how recursion is used:
            </p>
            <div className="rules">
              <p id="overflow">
                &lt;LETTER&gt; ::= A | B | C | D | E | F | G | H | I | J | K | L
                | M | N | O | P | Q | R | S | T | U | V | W | X | Y | Z
              </p>
              <p>A "LETTER" can be any character from A to Z.</p>
              <p>&lt;WORD&gt; ::= &lt;LETTER&gt; | &lt;LETTER&gt;&lt;WORD&gt;</p>
            </div>
            <p>
              This recursive definition means that a word can be a single letter or a letter followed by another word.
            </p>
            <p>
              This process of analyzing a statement using these rules is known as <strong>parsing</strong>.
            </p>
          </article>

          <h2 id="highlight">Syntax Diagram</h2>
          <img
            id="backusImg"
            src={backusSyntaxImg}
            alt="Backus-Naur syntax diagram"
            loading="lazy"
          />
          <p id="indent">
            For more information on regular expressions, click{' '}
            <a href="regular.html">here</a>. To explore other topics, click{' '}
            <a href="programmingtheory.html">here</a>.
          </p>
        </section>
      </main>
    </>
  );
};
