import React from 'react'
import style from '../../css/sortingsearch.css'
import binarySearch from '../../img/binarysearch.png'
import mergeSort from '../../img/mergesort.png'
import { Helmet } from 'react-helmet'
export const SortingAlgorithm = () => {
  return (
    <>
        <Helmet>
          <link rel="canonical" href="https://computerneek.org/sortingAlgorithm" />

        </Helmet>
        <div className="banner">
            <h1>Searching and Sorting Algorithms</h1>

        </div>

    
    <section className='sortingSection'>
        <h3 id="highlight">Sorting Algorithms</h3>
        <h4>Bubble sort</h4>
        <p>A bubble sort is a type of sorting algorithm that sorts an unordered list.</p>
        <h4>How does it work</h4>
        <p>A bubble sort works by swapping adjacent items until it's in order. It's the most inefficient of the sorting algorithms but it's easy to implement. As a result it is often used for small data sets. The time complexity of a bubble sort is <strong>O(n^2)</strong>.</p>
        <h4 id="merge">Merge Sort</h4>
        <p>A merge sort is a type of sorting algorithm that sorts an unordered list using a divide and conquer method.</p>
        <h4>How does it work</h4>
        <p>A merge sort halves the lists up until they are in pair.</p>
        <img id="img" src={mergeSort} alt="A picture of a merge sort"/>
        <h3 id="highlight">Searching Algorithms</h3>
        <h4>Linear Search</h4>
        <p>A linear search is a type of searching algorithm that sequentially checks each element of the list until a match is found. It has a time complexity of O(n).</p>
        <h4>Binary Search</h4>
        <p>A binary search is a type of searching algorithm that finds an item in a sorted list by a method of divide and conquer.</p>
        <h4>How does it work.</h4>
        <p>In a binary search, the midpoint of the item is found and from there, it checks if the desired value is greater than or less than that value. In the binary search example below, the number 4 is the value we are looking for and the red items are the values that are not needed. A binary search has a time complexity of <strong>O(Log n)</strong>.</p>
        <img id="imgM" src={binarySearch} alt="A picture of binary Search"/>
    </section>
    </>
  )
}
