const questionsData ={
    "questions" :{
        "What are the 3 stages to normalisation?" : "First normal form(1NF), second normal form(2NF) and third normal form(3NF)",
        "What are the advantages of normalisation?" : "It simplifies the data structure. It also reduces data redundancy and removes repeating data. It also makes it easy to update, remove and search data. It reduces the size of a database.",
        "What's the difference between flat file and relational database" : "A flat file is one big table of data whilst relational databases have many tables of data linked together.",
        "What are the disadvantages of a flat file?" : "Not all the data is needed at the same time. It also takes too much time to search and is prone to spelling mistakes.",
        "What are the advantages of a relational database?" : "There is no duplicate data, less data is stored making it smaller in size and its less prone to spelling errors.",
        "What is serialisation?" : "An technique used to ensure that transactions don't overlap in time.",
        "How does serialisation help combat the issue of multiple users accessing data at the exact same time?" : "The method only allows one transaction at a time from multiple connected clients",
        "How does timestamp ordering combat the issue of multiple users accessing data at the exact same time?" : "Every transaction will have a timestamp when it starts. The timestamp protocol orders the transactions based on the oldest protocole first.",
        "How does commitment ordering combat the issue of multiple users accessing data at the exact same time?" : "The same transactions will have priorities over others according to their dependencies as well as timestamp.",
        "How does a system know if another users has accessed the same thing using timestamp ordering?": "When a user saves an update , if the read timestamp is not the same as it was when they started the transaction, the database management system (DBMS) knows another users has accessed the same object.",
        "What are the advantages of a client-server system concerning a database": "The consistency of the database is maintained as only one copy is held. Access rights and security are managed centrally and backup and recovery are managed centrally.",
        "What are the disadvantages  of a client-server system concerning a database?" : "Simultaneous requests can cause the server to crash. The server is also a point of failure.",
        "Whats makes a database first normal form(1NF)?": "It is 1NF if it contains no repeating attributes or groups of attributes.",
        "How does record lock work in databases?" : "Locks records from modification and only allows records to be modified by one client at a time and unlocks after client is done.",
        "What are the benefits of commitment serialisation?" : "This ensures that no transactions are lost if 2 clients simultaneously try to update a record."
    }

}
export default questionsData;