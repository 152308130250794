import React from 'react'
import style from '../../css/dictionaries.module.css'
import { Helmet } from 'react-helmet'
import {HorizontalAds,SquareAds,VerticalAds,} from '../ads/index'

export const Dicitonaries = () => {
  return (
    <>
      <Helmet>
        <meta name="keywords" content="aqa a level computing, Dictionaries, Dictionary, Dictionaries computer science, dictionary computer, Data Structures, Algorithms, Programming, Preparation, aqa alevel computer science spec, Programming Examples, Computerneek"/>
        <meta name="description" content="A dictionary in computer science is a data type that stores unordered data. Each data has a unique key."/>
        <link rel="canonical" href="https://computerneek.org/dictionaries" />

      </Helmet>
        <div className="banner">
            <h1>Dictionaries</h1>
        
        </div>

        <section id={style.dictSection}>
            <h3 id={style.highlight}>What is a dictionary</h3>
            <p>A dictionary is a data type that stores unordered data. Each data has a unique key.</p>
            <h3 id={style.highlight}>How does a dictionary work</h3>
            <p>You call the key and the value is returned. In the example below, the names are the keys and the values are the corresponding numbers.</p>
            <div className={style.dictCode} style={{fontFamily:"monospace"}}>
                <pre className={style.csharp} style={{fontFamily:'monospace'}}/>  number <span style={{color: "#008000"}}>=</span> <span style={{color: "#008000",fontFamily:'monospace'}}>&#123;</span>“Steve” <span style={{color: "#008000",fontFamily:'monospace'}}>:</span> 07434575432,
                “Julian”<span style={{color: '#008000'}}>:</span> 08321453533,
                “Sarah” <span style={{color: '#008000'}}>:</span> 03210548576<span style={{color: "#008000"}}>&#125;</span><span style={{color: "#008000"}}>;</span>
                &nbsp;

            </div>
         
            <p id="indent">The pairs are in no particular order</p>
            

        </section>
    </>
    
  )
}
