const questionsData = {
    "questions": {
        "What are the 5 main functions of an OS?": "Memory Management, Processor Scheduling, Backing store Management, Peripheral Management / I/O Management.",
        "What is processor scheduling?": "Allocates time slices for different applications.",
        "What is ROM?": "Contains bootstrap information that is loaded up when the computer starts.",
        "How many bits does a half adder add together?": "2 bits.",
        "How many bits does a full adder add together?": "3 bits.",
        "What are the type of inputs that are needed for a OR gate to return true or false?": "At least one input must be true for the output to be true else the output is false.",
        "What requirements must be reached for a Not gate to return true or false?": "The input has to be false for the output to be true, and for the output to be false, the input must be true.",
        "Why do companies sell software in byte code?": "So that software can't be reversed engineered to find the source code.",
        "What is meant by imperative high level programming languages?": "When programmers specify what instructions are and are written in our language.",
        "What is the AQA specification boolean algebra sign for the OR gate?": "A + B",
        "What is Bit rate?": "Number of bits transmitted per second over a wired or wireless data link.",
        "What is Baud rate?": "Rate at which the signal communication channel changes state.",
        "What requirements must be reached for an NAND gate to return true or false?": "If both inputs are true, then the result is false; otherwise, the result is true.",
        "What are translators?": "Translators are used to convert high level programming languages into low level programming languages.",
        "Name 3 types of translators": "Compiler, Interpreter, Assembler",
        "What are the advantages of an interpreter(translator)?": "Executes line by line and stops when there is an error, hence making it easier to debug. Analysing is quick.",
        "What is the disadvantage of an interpreter(translator)?": "Execution time is longer.",
        "What are the advantages of an assembler?": "Allows for complex jobs to be easier. Memory efficient as it requires less memory. Its fast as execution time is small. It also has a one-to-one correspondence between machine code instruction.",
        "What requirements must be reached for an NOR gate to return true or false?": "Both inputs have to be false for the output to be true; otherwise, the output is false.",
        "What are some advantages of a compiler(a translator)?": "Compiler saves machine code so source code is no longer necessary. Compilers are quick to execute as code is already compiled. Since code is compiled, it doesn't take much memory to store.",
        "What are some disadvantages of a compiler(a translator)?": "Errors aren't usually spotted until a program is run. Also, the compiled code is hardware specific as different platforms have different binary code.",
        "What are the 4 main operations of System Software?": "Utility software, Translators, Library Programs, and the OS.",
        "What are the 3 main operations of Application Software?": "General system, Special purpose, Bespoke Software.",
        "What is an example of a function of Utility Software?": "Back-up Software",
        "What is an example of a type of Translator?": "Interpreter",
        "What is an example of a General System in Application Software?": "Word processor",
        "What is an example of a Special System in Application Software?": "A payroll System",
        "Why is the NAND gate known as a universal gate?": "Different combinations of the NAND gate can act like a NOT, OR, and AND gate.",
        "What are the benefits of using fewer logic gates?": "Reduces production costs, reduces heat produced, reduces energy requirement, increases the speed of a circuit.",
        "What is Utility Software?": "Utility software is designed to analyze, configure, optimize, or maintain a computer system.",
        "What is the OS?": "A program that manages a computer's resources.",
        "What is an example of an OS(operating system)?": "Windows",
        "What are the types of tasks a Utility software does?": "Defragmentation, install/uninstall software, virus checker, keep software up to date.",
        "What are some advantages of Bespoke Software?": "It's tailored for the client, making it more user-friendly and efficient. The client also has control over the software.",
        "What are some disadvantages of Bespoke Software?": "More expensive as companies have to cover all costs. It takes time to produce.",
        "What requirements must be reached for an XOR gate to return true or false?": "Only one input must be true for the output to return true, otherwise the output is false.",
        "What is the AQA boolean algebra for an XOR gate?": "A ⊕ B",
        "What does the D - Type Flip Flop do?": "Keeps track of the value of data which is captured and committed to memory at a specific moment in time.",
        "When does the Edge Trigger D change?": "Only changes in the rising of the edge of the clock signal, which is when it changes from 0 to 1."
    }
}
export default questionsData;