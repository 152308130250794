import React from 'react'
import header from '../../css/header.css'
import { Link, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlasses } from '@fortawesome/free-solid-svg-icons'

export const Header = () => {
    const navigate = useNavigate()

    

    
  return (
    <>
   
        <header>

                
            <div className="iconContainer">
                <FontAwesomeIcon icon={faGlasses}className={"fa-solid-glasses"} />
                <Link className={"icon-link-styles"}to={'/home'}><h1>Computer Neek</h1></Link>
            </div>
            <nav id='navbar'>
                <ul id="navbarcontainer">
                    <li>
                        <Link className={"link-styles"} to={'/home'}>Home</Link>
                    </li>
                    <li>
                        <Link className={"link-styles"} to={'/programming'}>Programming</Link>
                    </li>
                    <li>
                        <Link className={"link-styles"} to={'/programmingTheory'}>Programming Concepts</Link>
                    </li>
                    <li>
                        <Link className={"link-styles"} to={'/pastB'}>Past Section B Solutions</Link>
                    </li>
                    <li>
                        <Link className={"link-styles"} to={'/topics'}>Flashcards</Link>
                    </li>
                   

                </ul>

            </nav>
        </header>

    </>
   
   
   
  
 

  
    
  )
}
