import React from 'react'
import { Link } from 'react-router-dom'
import style from '../../css/programmingCheckpoint.module.css'
import { Helmet } from 'react-helmet'
export const ProgrammingCheckpoint = () => {
  return (
    <>
        <Helmet>
        <link rel="canonical" href="https://computerneek.org/programmingCheckpoint" />

        </Helmet>
        
        <div className="donateandmaincon">

            <div className={style.banner}>
                
                <h2 className={style.bannerh2}>Computer Science Programming Resources</h2>
            </div>
            <section id='mainContent'>
                <h4 id="aspects"> <Link to={'#'} id="aspectsLink">Aspects Covered</Link></h4>

                
                <div className="buttons">
                    <Link to={'/programming'}><button id="probuttons1">Programming</button></Link>
                    <Link to={'/programmingTheory'}><button id="probuttons2">Programming Concepts</button></Link>
                  

                </div>
            
            </section>


        </div>
    </>
  )
}
