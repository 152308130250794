import React from 'react'

import index from '../../css/index.css'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'

export const Home = () => {
    
  return (
    <>
        <Helmet>
            <title>ComputerNeek</title>
            <link rel="canonical" href='https://computerneek.org'></link>

        </Helmet>
                        <div className="donateandmaincon">

            <div className="banner">
                
                <h2>Learn Computer Science the right way</h2>
                <p>Welcome to Computer Neek, a free and easy-to-use online learning platform that ensures success.</p>
            </div>


            <section id="mainContent">
            
                <h4 id="aspects"> <Link id="aspectsLink">Aspects Covered</Link></h4>

                
                <div className="buttons">
                    <Link className='linkButtons' to={'/topics'} ><button id="probuttons1">Theory Flashcards</button></Link>
                    <Link className='linkButtons' to={'/programmingCheckpoint'}><button id="probuttons2">Programming</button></Link>
                    <Link className='linkButtons' to={'/programmingTheory'}><button id="probuttons3">Programming Theory</button></Link>
                    
                

                </div>
                

            </section>


            </div>
    </>


    
        

   
    
  )
  
}
