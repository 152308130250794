import { Link } from "react-router-dom"
import { Helmet } from "react-helmet"
const Missing = () => {
    return (
        <>
            <Helmet>
                <link rel="canonical" href="https://computerneek.org/missing" />
            </Helmet>
            <article style={{ padding: "100px" }}>
                <h1>Oops!</h1>
                <p>Page Not Found</p>
                <div className="flexGrow">
                    <Link to="/home">Visit Our Homepage</Link>
                </div>
            </article>
        
        </>
    
       
    )
}

export default Missing
